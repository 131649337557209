<script>
	import tippy from 'sveltejs-tippy';
	import { _, locale } from 'svelte-i18n';
	import FullPageModal from './FullPageModal.svelte';
	import CloseIcon from '$lib/icon/CloseIcon.svelte';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import PhoneIcon from '$lib/icon/PhoneIcon.svelte';
	import VerifyPhone from '$lib/VerifyPhone.svelte';
	import LoginProvider from '$lib/LoginProvider.svelte';

	let {
		label = '',
		providers = [],
		accountToUse = undefined,
		useDifferentManaged = undefined,
		contactLoginSuccess = () => {},
		handleContactError = () => {},
		continueWithEthereumExtension = () => {},
		continueWithProvider = () => {},
		close = () => {}
	} = $props();
</script>

<FullPageModal css="stack:-mt-48" {close} dataTest="login-required-modal">
	<button class="absolute right-0 top-0 m-6 cursor-pointer" onclick={close}>
		<CloseIcon />
	</button>
	<div class="flex w-full flex-col items-start">
		<h1 class="-mt-2">{label}</h1>
		<div class="mt-4 w-full space-y-2">
			{#each providers as provider}
				{#if provider.slug === 'email'}
					<div class="btn-border hover-none relative h-auto rounded-md px-4">
						<button class="flex h-16 w-full items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'mr-4 text-right'
									: 'ml-4 text-left'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{provider.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								email={provider.login_hint}
								{accountToUse}
								{useDifferentManaged}
								login
								disabled
								success={contactLoginSuccess}
								error={handleContactError}
							/>
						</div>
					</div>
				{:else if provider.slug === 'phone'}
					<div class="btn-border hover-none relative h-auto rounded-md px-4">
						<button class="flex h-16 w-full items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'mr-4 text-right'
									: 'ml-4 text-left'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{provider.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								phone={provider.login_hint}
								{accountToUse}
								{useDifferentManaged}
								login
								disabled
								success={contactLoginSuccess}
								error={handleContactError}
							/>
						</div>
					</div>
				{:else if provider.slug === 'ethereum'}
					<div class="btn-border hover-none relative h-auto space-y-2 rounded-md p-2">
						<span class="mb-3 block text-left"
							>{provider.wallet?.name || 'Ethereum'}
							{#if provider.wallet?.display}
								<span use:tippy={{ content: provider.login_hint, placement: 'top' }}>
									({provider.wallet?.display})
								</span>
							{/if}
						</span>
						{#if provider.slug === 'ethereum' && window.ethereum}
							<LoginProvider
								ethereum={() =>
									continueWithEthereumExtension({
										info: provider,
										accountToUse,
										useDifferentManaged
									})}
								{provider}
								hideusername
								prefix="Continue with"
							/>
						{/if}
					</div>
				{:else}
					<LoginProvider
						login={true}
						onclick={() => {
							continueWithProvider({
								slug: provider.slug,
								body: {
									login_hint: provider.login_hint,
									accountToUse,
									useDifferentManaged
								},
								server: provider.slug === 'mastodon' ? provider.mastodonServer : null
							});
						}}
						{provider}
						prefix="Continue with"
					/>
				{/if}
			{/each}
		</div>
	</div>
</FullPageModal>
