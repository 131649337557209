<script>
	import { onMount } from 'svelte';
	import { _ } from 'svelte-i18n';
	import { slide, fade } from 'svelte/transition';
	import { replace as replaceRoute, push as navigateTo } from 'svelte-spa-router';
	import { global } from '$src/state.svelte.js';
	import { getProfile } from '$utils/api.js';
	import { setAttributes } from '$utils/common.js';
	import { clearLocalAndSessionStorage } from '$utils/storage.js';
	import { FADE_ANIM_DELAY_MS, SLIDE_ANIM_MS } from '$src/constants.js';
	import { notification } from '$src/stores.js';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import Dropdown from '$lib/Dropdown.svelte';
	import TitleBar from '$lib/TitleBar.svelte';
	import LoginProviderGroup from '$lib/LoginProviderGroup.svelte';
	import Notification from '$lib/Notification.svelte';

	let continueWithEmail = $state(false);

	onMount(async () => {
		global.spinner = true;

		//we dont have profile data
		if (!global.data?.version) {
			global.data = await getProfile();
			if (!global.data.isPersonalLoggedIn && !global.data.isManagedLoggedIn) {
				clearLocalAndSessionStorage();
				return replaceRoute('/login');
			}
			if (global.data.actions?.doneWizardAt) return replaceRoute('/');
		}

		if (global.data.preferred?.length) return replaceRoute('/wizard/status');

		localStorage.setItem('currentWizardStage', 'preferredprovider');
		global.spinner = false;
	});

	async function verifyEmailSuccess() {
		continueWithEmail = false;
		global.data = await getProfile();
		notification.clear(); //remove outstanding notification
		return navigateTo('/');
	}
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if !global.spinner}
	{@const managed = global.data?.managed?.find((i) => global.data?.subjects?.includes(i.id))}
	<main class="flex-1 overflow-y-auto px-3 pb-16 md:px-0">
		{#if managed}
			<section
				class="mx-auto mt-8 max-w-md rounded-md bg-[#DCDCDC] px-2 pb-3 pt-2 dark:bg-[#505050]"
				in:fade={{ FADE_ANIM_DELAY_MS }}
			>
				<span class="block text-left text-sm font-medium">{$_('You logged in with')}</span>
				<div
					class="text-charcoal mt-2 flex flex-col items-start justify-center rounded-md px-2 dark:text-[#D4D4D4]"
				>
					<div class="mt-1 inline-flex items-center gap-x-2.5">
						<span class="inline-flex items-center justify-center rounded-sm">
							<span class="managed-rounded-square-sm -ml-0">
								<img src={managed.logo} alt="logo" class="w-4.5 max-h-[18px] object-contain" />
							</span>
						</span>
						<span>
							{managed.user_name}
						</span>
					</div>
					<span class="mt-3 block text-left text-sm font-medium"
						>{$_('an account managed by an organization')}</span
					>
				</div>
				<span class="mt-5 block text-left text-lg font-medium italic"
					>{$_('You need a personal provider for your wallet')}</span
				>
			</section>
		{/if}

		<div>
			<h1
				class="mx-auto my-6 border-none text-center text-base font-medium md:text-2xl"
				in:fade={{ delay: 2 * FADE_ANIM_DELAY_MS }}
			>
				{$_('Select your preferred login provider')}
			</h1>

			<section class="mx-auto mt-6 max-w-md" in:fade={{ delay: 3 * FADE_ANIM_DELAY_MS }}>
				<LoginProviderGroup
					login={true}
					cryptoWallets={false}
					prefix="Continue with"
					showManagedLogin={false}
					choosePreferred={true}
				>
					<Dropdown
						dataTest="add-email-btn"
						expanded={continueWithEmail}
						ariaLabel={$_('Continue with Email')}
						onclick={() => {
							continueWithEmail = !continueWithEmail;
						}}
					>
						<div class="flex h-12 w-full items-center justify-start gap-x-4 px-4">
							<MailIcon />
							<span class="block text-left" aria-hidden="true">
								{$_('Continue with Email')}
							</span>
						</div>
						{#if continueWithEmail}
							<div class="px-3 pb-3 pt-1" transition:slide={{ duration: SLIDE_ANIM_MS }}>
								<VerifyEmail success={verifyEmailSuccess} login={true} choosePreferred={true} />
							</div>
						{/if}
					</Dropdown>
				</LoginProviderGroup>
			</section>
		</div>
	</main>

	<wc-footer use:setAttributes></wc-footer>
{/if}
