<script>
	import { locale } from 'svelte-i18n';
	import tippy from 'sveltejs-tippy';
	import HelpIcon from '$lib/icon/HelpIcon.svelte';

	let { content = '', ariaLabel = '', placement = 'top', css = '' } = $props();
</script>

<button
	use:tippy={{
		content,
		placement
	}}
	aria-label={ariaLabel}
	class="{$locale && $locale.startsWith('ar') ? 'mr-1' : 'ml-1'} {css}"
>
	<HelpIcon />
</button>
