<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo } from 'svelte-spa-router';
	import { SESSION_EXPIRE_SHOW_MODAL_MS, SESSION_MODAL_WARNING_MS } from '$src/constants.js';
	import { keepAlive, deleteLogin } from '$utils/api.js';
	import { global } from '$src/state.svelte.js';
	import { countdown } from '$src/stores.js';
	import { stopPropagation } from '$utils/common.js';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import FullPageModal from '$lib/modal/FullPageModal.svelte';

	let { close = () => {} } = $props();

	const max = SESSION_EXPIRE_SHOW_MODAL_MS / 1000;
	const value = $derived(SESSION_EXPIRE_SHOW_MODAL_MS / 1000 - $countdown);

	const progressPath = $derived(() => {
		if (value <= 0) {
			return '';
		} else if (value >= max) {
			return 'M50,5A45 45 0 1 1 49.9999 5';
		} else {
			const angle = Math.PI * 2 * (value / max);
			const x = 50 + Math.cos(angle - Math.PI / 2) * 45;
			const y = 50 + Math.sin(angle - Math.PI / 2) * 45;
			let path = 'M50,5';
			if (angle > Math.PI) {
				path += 'A45 45 0 0 1 50 95';
			}
			path += `A45 45 0 0 1 ${x} ${y}`;
			return path;
		}
	});

	$effect(() => {
		value,
			(function () {
				// TODO: THIS IS A HACK -> IF TIMER EXPIRED, SHOW MODAL SPINNER
				if (value === (SESSION_MODAL_WARNING_MS / 1000) * -1) {
					logoutAjax = true;
				}
			})();
	});

	let logoutAjax = $state(false);
	async function logout() {
		logoutAjax = true;
		await deleteLogin({ clearSession: false });
		global.data = {};
		close();
		navigateTo('/login');
	}

	let renewAjax = $state(false);
	async function renew() {
		renewAjax = true;
		await keepAlive();
		close();
	}
</script>

<FullPageModal close={renew}>
	{#if logoutAjax}
		<SpinnerIcon css="h-9 w-9" />
	{:else}
		<h1 class="text-center text-lg font-semibold">{$_('You are about to be logged out')}</h1>
		<div class="my-6 flex items-center justify-center">
			<svg viewBox="0 0 100 100" class="h-32 w-32" fill="transparent">
				<path
					d="M50,5A45 45 0 1 1 49.9999 5"
					class={value >= (SESSION_EXPIRE_SHOW_MODAL_MS - SESSION_MODAL_WARNING_MS) / 1000
						? 'text-red-500'
						: 'text-charcoal dark:text-[#d4d4d4]'}
					stroke-width="10px"
					stroke="currentColor"
				/>
				<path
					d={progressPath()}
					class="text-gray-300 dark:text-[#808080]"
					stroke-width="10px"
					stroke="currentColor"
				/>
			</svg>
			<span
				class="absolute text-5xl font-bold"
				class:text-red-500={value >=
					(SESSION_EXPIRE_SHOW_MODAL_MS - SESSION_MODAL_WARNING_MS) / 1000}
				>{!renewAjax ? SESSION_EXPIRE_SHOW_MODAL_MS / 1000 - value : ''}</span
			>
		</div>

		<div class="flex w-full max-w-sm items-center justify-between">
			<button
				onclick={stopPropagation(logout)}
				disabled={logoutAjax}
				class="inline-flex h-9 w-24 items-center justify-center hover:underline focus:underline"
			>
				{$_('Log out')}
			</button>

			<!-- svelte-ignore a11y_autofocus -->
			<button
				autofocus
				onclick={stopPropagation(renew)}
				disabled={renewAjax}
				class="btn-background dark:text-charcoal inline-flex h-10 w-44 items-center justify-center dark:bg-white dark:ring-white"
			>
				{#if renewAjax}
					<SpinnerIcon css="h-5 w-5 text-white dark:text-[#d4d4d4]" />
				{:else}
					{$_('Stay Logged In')}
				{/if}
			</button>
		</div>
	{/if}
</FullPageModal>
