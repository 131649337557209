<script>
	import { _, locale } from 'svelte-i18n';
	import { global } from '$src/state.svelte.js';
	import tippy from 'sveltejs-tippy';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import VerifyPhone from '$lib/VerifyPhone.svelte';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import PhoneIcon from '$lib/icon/PhoneIcon.svelte';
	import logins from '$svr/providers/logins.json';

	let {
		continueWithProvider = () => {},
		continueWithEthereumExtension = () => {},
		contactLoginSuccess = () => {},
		handleContactError = () => {}
	} = $props();

	const validLoginSlugs = logins.map((i) => i.slug);
</script>

<section
	data-test="recovery-providers-container"
	class="-mx-2 mt-3 rounded-md bg-[#DCDCDC] p-2 dark:bg-[#505050]"
>
	<h2
		class="{$locale && $locale.startsWith('ar')
			? 'text-right'
			: 'text-left'} mb-2 block text-sm font-medium"
	>
		{$_('Or log in with two recovery providers')}
	</h2>

	<div class="space-y-2">
		{#each global.data.recovery as recovery}
			{#if recovery.slug === 'email'}
				<div class="btn-border hover-none relative h-auto rounded-md px-4">
					<button class="flex h-16 w-full items-center justify-start">
						<MailIcon size="lg" />
						<div
							class="flex flex-col {$locale && $locale.startsWith('ar')
								? 'mr-4 text-right'
								: 'ml-4 text-left'}"
						>
							<span>
								{$_('Continue with Email')}
							</span>
							<span>{recovery.login_hint}</span>
						</div>
					</button>
					<div class="pb-4 pt-1">
						<VerifyEmail
							email={recovery.login_hint}
							accountToUse="personal"
							login
							disabled
							success={contactLoginSuccess}
							error={handleContactError}
						/>
					</div>
				</div>
			{:else if recovery.slug === 'phone'}
				<div class="btn-border hover-none relative h-auto rounded-md px-4">
					<button class="flex h-16 w-full items-center justify-start">
						<PhoneIcon size="lg" />
						<div
							class="flex flex-col {$locale && $locale.startsWith('ar')
								? 'mr-4 text-right'
								: 'ml-4 text-left'}"
						>
							<span>
								{$_('Continue with Phone')}
							</span>
							<span>{recovery.login_hint}</span>
						</div>
					</button>
					<div class="pb-4 pt-1">
						<VerifyPhone
							phone={recovery.login_hint}
							login
							accountToUse="personal"
							disabled
							success={contactLoginSuccess}
							error={handleContactError}
						/>
					</div>
				</div>
			{:else if recovery.slug === 'ethereum'}
				{#if window.ethereum}
					<div class="btn-border hover-none relative h-auto space-y-2 rounded-md p-2">
						<span class="mb-3 block text-left"
							>{recovery.wallet?.name || 'Ethereum'}
							{#if recovery.wallet?.display}
								<span use:tippy={{ content: recovery.login_hint, placement: 'top' }}>
									({recovery.wallet?.display})
								</span>
							{/if}
						</span>
						<LoginProvider
							ethereum={() => continueWithEthereumExtension({ info: recovery })}
							provider={recovery}
							hideusername
							prefix="Continue with"
						/>
					</div>
				{/if}
			{:else if validLoginSlugs.includes(recovery.slug)}
				<LoginProvider
					login={true}
					onclick={() =>
						continueWithProvider({
							slug: recovery.slug,
							body: {
								accountToUse: 'personal',
								login_hint: recovery.login_hint
							},
							server: recovery.slug === 'mastodon' ? recovery.mastodonServer : null
						})}
					provider={recovery}
					prefix="Continue with"
				/>
			{/if}
		{/each}
	</div>
</section>
