import {
	timeout,
	manageSessionCountdown,
	manageLoginStatus,
	cachePopHeader
} from '$utils/common.js';
import { global } from '$src/state.svelte.js';
import { countdown } from '$src/stores.js';
import { API_BASE, CLIENT_REQUEST_TIMEOUT_MS } from '$src/constants.js';

const send = async (method, path, query, params) => {
	let response, json, error;

	const opts = {
		method,
		headers: { 'x-hello-pop': global.helloPop }
	};

	if (params instanceof FormData) {
		// binary (picture, etc)
		opts.body = params;
	} else if (params) {
		// json
		opts.headers['Content-Type'] = 'application/json';
		opts.body = JSON.stringify(params);
	}

	const base = API_BASE + path;
	const endpoint = query ? base + '?' + query : base;

	try {
		response = await timeout(fetch(endpoint, opts), CLIENT_REQUEST_TIMEOUT_MS);
		cachePopHeader(response);

		// what is the response type? and only parse json if res type is json
		// check response header = content-length

		json = await response.json();
		if (response.ok) {
			manageLoginStatus(json);
			manageSessionCountdown(json);
			return json;
		}
	} catch (err) {
		// ReferenceError, TypeError, SyntaxError
		// NetworkError, JSONParseError
		error = err;
	}

	if (!error) {
		if (response?.status >= 500) {
			error = new Error('~500 server in a confused state');
		} else if (response?.status >= 400) {
			error = new Error('~400 client called server with bad data');
		}
	}

	const api = {
		status: response?.status,
		requestPath: method + ' ' + endpoint,
		requestBody: params || 'No JSON Request Body',
		responseBody: json || 'No JSON Response Body'
	};
	error.api = api;

	countdown.clear();
	global.unrecoverableError = true;

	throw error;
};

export { send };
