import { UAParser } from 'ua-parser-js';

const getDeviceInfo = (_ua) => {
	const ua = new UAParser(_ua);
	const os = ua?.getOS()?.name;
	const type = ua?.getDevice()?.type;
	const browser = ua?.getBrowser()?.name;
	const isApple = ['macOS', 'iOS'].includes(os);
	const isWindows = ['Windows', 'Windows Phone', 'Windows Mobile', 'Xbox'].includes(os);
	const isAndroid = ['Android', 'Android-x86'].includes(os);
	const isLinux = ['Linux'].includes(os);
	const isTypeTablet = type === 'tablet';
	const isTypeMobile = type === 'mobile';
	const isTypeUnknown = type === undefined;

	return {
		os,
		type,
		browser,
		isApple,
		isAndroid,
		isWindows,
		isLinux,
		isTypeTablet,
		isTypeMobile,
		isTypeUnknown
	};
};

const deviceTheme =
	window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export { getDeviceInfo, deviceTheme };
