<script>
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { _, locale } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import TitleBar from '$lib/TitleBar.svelte';
	import { global } from '$src/state.svelte.js';
	import { notification } from '$src/stores.js';
	import { getProfile, postWizard, keepAlive } from '$utils/api.js';
	import { FADE_ANIM_DELAY_MS } from '$src/constants.js';
	import WizardProgress from '$lib/WizardProgress.svelte';
	import Notification from '$lib/Notification.svelte';
	import { setAttributes } from '$utils/common.js';
	import { clearLocalAndSessionStorage } from '$utils/storage.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import InfoIcon from '$lib/icon/InfoIcon.svelte';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';

	let nextPageAjax = $state(false);

	onMount(async () => {
		global.spinner = true;

		//we dont have profile data
		if (!global.data?.version) {
			global.data = await getProfile();

			if (!global.data.isPersonalLoggedIn && !global.data?.isManagedLoggedIn) {
				clearLocalAndSessionStorage();
				return replaceRoute('/login');
			}

			if (global.data.actions?.doneWizardAt) return replaceRoute('/');

			//we do not have 2 recoveries
			if (
				global.data.profile?.accounts?.filter((i) => i.recovery && !i.preferred && !i.communal)
					.length < 2
			)
				return replaceRoute('/wizard/incomplete');
		}

		if (!global.data?.preferred?.length) return replaceRoute('/wizard/preferredprovider');

		localStorage.setItem('currentWizardStage', 'status');

		logWizardFunnelPlausibleEvent();
		logPlausibleEvent({ u: '/wizard/status' });

		global.spinner = false;
	});

	function logWizardFunnelPlausibleEvent() {
		//Wizard Funnel
		const indexOfCurrentFunnelStep = window.wizardFunnel.indexOf(sessionStorage.wiz_funnel);
		const indexOfNextFunnelStep = window.wizardFunnel.indexOf('wiz_status');
		//wizard funnel state is valid and not already sent
		if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
			const preferred = global.data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				if (sessionStorage.welcome_app_info) {
					try {
						welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
					} catch (err) {
						console.error(err);
					}
				}
			}
			logPlausibleEvent({
				n: 'Wiz Status',
				p: { preferred, welcome_email_app },
				u: '/wizard/status'
			});
			sessionStorage.setItem('wiz_funnel', 'wiz_status');
		}
	}

	async function nextPage() {
		keepAlive();
		nextPageAjax = true;
		const recoveries = global.data.profile?.accounts.filter(
			(i) => i.recovery && !i.preferred && !i.communal
		);
		if (recoveries.length >= 2) {
			if (!global.data.profile.doneWizardAt) {
				await postWizard();
				//End of Wizard Funnel
				if (sessionStorage.wiz_funnel === 'wiz_recovery_2_success') {
					const preferred = global.data?.preferred?.[0]?.slug;
					let welcome_email_app;
					if (sessionStorage.welcome_app_info) {
						if (sessionStorage.welcome_app_info) {
							try {
								welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
							} catch (err) {
								console.error(err);
							}
						}
					}
					const recovery_1 = global.data?.recovery?.[0]?.slug;
					const recovery_2 = global.data?.recovery?.[1]?.slug;
					await logPlausibleEvent({
						n: 'Wiz Complete',
						u: '/status',
						p: { preferred, welcome_email_app, recovery_1, recovery_2 }
					});
					sessionStorage.removeItem('wiz_funnel');
				}
			}
			const profile_data = await getProfile();
			global.data = profile_data;
			localStorage.setItem('showProfileLandingModal', true);
			localStorage.removeItem('currentWizardStage');
			notification.clear();
			navigateTo('/');
		} else {
			return navigateTo('/wizard/recoveryprovider');
		}
	}

	const handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			nextPage();
		}
	};

	function skip() {
		keepAlive();
		const preferred = global.data.profile?.accounts?.find((i) => i.preferred);
		const nonSocialProviders = ['email', 'phone'];
		const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug);
		const socialAccountLinked = global.data.profile?.accounts.filter(
			(i) => ![...nonSocialProviders, 'ethereum'].includes(i.slug) && !i.managed
		).length;
		const recoveries = global.data.profile?.accounts.filter(
			(i) => i.recovery && !i.preferred && !i.communal
		);

		if (!isPreferredSocial && socialAccountLinked) {
			return navigateTo('/wizard/upgrade');
		} else if (recoveries.length < 2) {
			return navigateTo('/wizard/incomplete');
		} else {
			return navigateTo('/wizard/status');
		}
	}
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

<svelte:window onkeydown={handleEnterKey} />

{#if global.data?.isPersonalLoggedIn && !global.spinner}
	{@const recoveryProviders = global.data?.profile?.accounts?.filter(
		(i) => i.recovery && !i.preferred
	)}
	<main class="flex-1 overflow-y-auto pb-16">
		<div class="mt-8">
			{#if global.data?.preferred?.length}
				<div
					class="mx-auto flex max-w-2xl items-center justify-between space-x-2 px-3 text-sm md:space-x-0"
					in:fade|global={{ delay: 0 * FADE_ANIM_DELAY_MS }}
				>
					<h1 class="flex-1 border-none text-center text-xl font-medium">
						{$_("This is how you'll be logging in")}
					</h1>
				</div>
			{/if}
			<div class="mx-auto px-3 text-center md:max-w-2xl md:px-0">
				<WizardProgress />

				<button
					data-test="status-continue-btn"
					onclick={nextPage}
					disabled={nextPageAjax}
					class="{!nextPageAjax
						? 'h-auto py-2.5'
						: ''} btn-background inline-flex w-full max-w-md items-center justify-center text-lg disabled:cursor-not-allowed disabled:opacity-50"
					in:fade|global={{ delay: 4 * FADE_ANIM_DELAY_MS }}
				>
					{#if nextPageAjax}
						<SpinnerIcon css="h-5 w-5" />
					{:else if recoveryProviders?.length >= 2}
						{$_('Continue')}
					{:else if recoveryProviders?.length === 1}
						{$_('Add second recovery provider')}
					{:else}
						{$_('Add recovery providers')}
					{/if}
				</button>

				<div
					class="mx-auto my-4 flex w-full max-w-md items-center gap-x-2 text-sm opacity-80"
					in:fade|global={{ delay: 5 * FADE_ANIM_DELAY_MS }}
				>
					<InfoIcon />
					<span class={$locale && $locale.startsWith('ar') ? 'text-right' : 'text-left'}
						>{$_('You can change your providers at wallet.hello.coop')}</span
					>
				</div>

				{#if recoveryProviders?.length < 2}
					<button
						onclick={skip}
						data-test="skip-to-profile-btn"
						class="btn-border w-full max-w-md"
						in:fade|global={{ delay: 6 * FADE_ANIM_DELAY_MS }}
					>
						{$_('Skip to profile')}
					</button>
				{/if}
			</div>
		</div>
	</main>

	<wc-footer use:setAttributes></wc-footer>
{/if}
