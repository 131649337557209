<script>
	import RemoveIcon from '$lib/icon/RemoveIcon.svelte';

	let { dataTest = '', onclick = () => {} } = $props();

	let hover = $state(false);
</script>

<button
	aria-label="Remove"
	data-test={dataTest}
	onmouseenter={() => (hover = true)}
	onmouseleave={() => (hover = false)}
	onfocus={() => (hover = true)}
	onblur={() => (hover = false)}
	{onclick}
	class="inline-flex items-center justify-start"
>
	<RemoveIcon {hover} />
</button>
