import { replace as replaceRoute } from 'svelte-spa-router';
import { logPlausibleEvent } from './plausible';
import { global } from '$src/state.svelte.js';
import { getRecommendedProviders } from '$utils/common.js';
import logins from '$svr/providers/logins.json';

const handleConsentResponse = (res) => {
	const responseURI = new URL(res.uri);
	for (const key in res.params) {
		responseURI.searchParams.append(key, res.params[key]);
	}
	if (!res.response_mode) {
		//we did not get a response_mode query param
		//set to response_type default response_modes
		if (res.response_type === 'code') {
			res.response_mode = 'query';
		} else if (res.response_type === 'id_token') {
			res.response_mode = 'fragment';
		}
	}

	sessionStorage.clear(); //clears authorize_query_params, app

	switch (res.response_mode) {
		case 'form_post': {
			const formRef = document.createElement('form');
			formRef.setAttribute('method', 'POST');
			formRef.setAttribute('action', res.uri);
			if (res.params) {
				for (const key in res.params) {
					const inputRef = document.createElement('input');
					inputRef.setAttribute('type', 'hidden');
					inputRef.setAttribute('name', key);
					inputRef.setAttribute('value', res.params[key]);
					formRef.appendChild(inputRef);
				}
				document.body.appendChild(formRef);
			}
			formRef.submit();
			break;
		}
		case 'query': {
			// Do nothing - already added as search params
			window.location.href = responseURI.href;
			break;
		}
		default: {
			// Fragment
			responseURI.href = responseURI.href.replace('?', '#');
			window.location.href = responseURI.href;
			break;
		}
	}
};

const saveAuthParams = (params) => {
	// On logout, we need the original query parameters to make the getConsent call.
	// This is useful if the user wants to switch accounts.
	// Remove login_hint to clear the login_hint prompt state if switching accounts.
	const saveParams = new URLSearchParams(params);
	saveParams.delete('login_hint');
	sessionStorage.setItem('authorize_query_params', saveParams.toString());
};

const logAuthParams = (params) => {
	const debugAuthParamsJson = Object.fromEntries(params.entries());
	console.log('Auth Params:', debugAuthParamsJson);
};

function wizard(consent) {
	// managed wizard
	const managed = !!consent.chooseWhoManages || !!consent.chooseManagedLogo;
	if (managed) return replaceRoute('/wizard/managed');
	const managedEmail = !!consent.verifyManagedEmail;
	if (managedEmail) return replaceRoute('/wizard/email');

	// claims wizard
	const routes = {
		recovery: '/wizard/recovery',
		verified_names: '/wizard/verified-name',
		existing_names: '/wizard/existing-name',
		existing_usernames: '/wizard/existing-username',
		names: '/wizard/name',
		nicknames: '/wizard/preferred-name',
		given_names: '/wizard/first-name',
		family_names: '/wizard/last-name',
		emails: '/wizard/email',
		phones: '/wizard/phone',
		ethereums: '/wizard/ethereum',
		discords: '/wizard/discord',
		twitters: '/wizard/twitter',
		githubs: '/wizard/github',
		gitlabs: '/wizard/gitlab'
	};
	const requested_scopes = Object.keys(consent.release);
	const order_of_routes = Object.keys(routes);

	for (const route of order_of_routes) {
		if (!requested_scopes.includes(route)) continue;

		if (route === 'recovery') {
			if (consent.recovery?.length < 2) {
				return replaceRoute(routes[route]);
			}
		} else if (route === 'verified_names') {
			if (
				consent.release?.verified_names &&
				!Object.keys(consent.release.verified_names).filter(
					(i) => Object.keys(consent.release.verified_names[i]).length >= 2
				).length
			)
				return replaceRoute(routes[route]);
		} else if (route === 'existing_names') {
			if (consent.release?.existing_names && !Object.keys(consent.release.existing_names).length)
				return replaceRoute(routes[route]);
		} else if (route === 'existing_usernames') {
			if (
				consent.release?.existing_usernames &&
				!Object.keys(consent.release.existing_usernames).length
			)
				return replaceRoute(routes[route]);
		} else if (!consent.release[route].length) {
			return replaceRoute(routes[route]);
		}
	}
	return false;
}

const ERRORS = {
	invalid_request: 'Invalid Request',
	unauthorized_client: 'Unauthorized Client',
	'invalid response_mode': 'Invalid Response Mode',
	access_denied: 'Request cancelled',
	user_cancelled_authorize: 'Request cancelled',
	unsupported_response_type: 'Unsupported Response Type',
	'must be code or id_token': 'Must be code or id token',
	server_error: 'Server Error',
	temporarily_unavailable: 'Temporarily Unavailable',
	invalid_client: 'Invalid Client',
	invalid_grant: 'Invalid Grant',
	unsupported_grant_type: 'Unsupported Grant Type',
	invalid_scope: 'Invalid Scope',
	'missing or invalid scope': 'Missing or invalid scope',
	'missing code_challenge': 'Missing code challenge',
	UNSUPPORTED_REDIRECT_URI: 'Redirect URI is not supported',
	ACCESS_DENIED: 'User does not have access to Dev Redirect URIs'
};

async function logProviderResPlausibleEvents(params, consent) {
	//End of Email Upgrade Funnel
	//email upgrade funnel state is valid and not already sent + log in success
	if (
		sessionStorage.email_upgrade_funnel === 'email_upgrade_start' &&
		(consent.isPersonalLoggedIn || consent.isManagedLoggedIn)
	) {
		const email_domain = consent?.preferred?.[0]?.user_name?.split('@')[1];
		logPlausibleEvent({
			n: 'Email Upgrade Success',
			p: { email_domain, slug: params.get('provider') },
			u: '/'
		});
		sessionStorage.removeItem('email_upgrade_funnel');
	}

	//New User Release Funnel
	const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
		sessionStorage.az_release_funnel
	);
	const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
	//session funnel state is valid and not already sent + logged in + not link flow
	if (
		indexOfCurrentFunnelStep !== -1 &&
		indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
		(consent?.isPersonalLoggedIn || consent?.isManagedLoggedIn) &&
		consent?.notification?.type !== 'link'
	) {
		const provider = params.get('provider');
		const client_id =
			new URLSearchParams(sessionStorage.authorize_query_params)?.get('client_id') ||
			global?.data?.app?.client_id;
		const redirect_uri =
			new URLSearchParams(sessionStorage.authorize_query_params)?.get('redirect_uri') ||
			global?.data?.app?.redirect_uri;
		let redirect;
		try {
			redirect = new URL(redirect_uri)?.hostname;
		} catch (err) {
			console.error(err);
		}
		global.spinner = true;
		//we are await-ing because response can be a redirect back to app - we dont want to lose capturing events
		if (
			!consent?.uri &&
			!consent?.response_mode &&
			consent?.isNewUser &&
			!consent?.release?.previous
		) {
			//existing user: not auto-flow + isNewUser + not rerelease
			await logPlausibleEvent({
				n: 'AZ Login Success',
				p: { client_id, provider, redirect },
				u: '/'
			});
			sessionStorage.setItem('az_release_funnel', 'az_login_success');
		} else {
			await logPlausibleEvent({
				n: 'AZ Existing User',
				p: { client_id, provider, redirect },
				u: '/'
			});
			sessionStorage.removeItem('az_release_funnel');
		}
	}
}

const logReleaseFunnelEndPlausible = async (consent) => {
	//End of User Release Funnel
	const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
		sessionStorage.az_release_funnel
	);
	const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_release');
	//session funnel state is valid and not already sent + new user (isNewUser flag + not rerelease (`previous` flag in claim object))
	if (
		indexOfCurrentFunnelStep !== -1 &&
		indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
		consent.isNewUser &&
		!consent.release?.previous
	) {
		await logPlausibleEvent({ n: 'AZ Release', u: '/' });
		sessionStorage.removeItem('az_release_funnel');
	}
};

const processProviderHintParam = (authorizeQueryParams, consentData) => {
	const searchParams = new URLSearchParams(authorizeQueryParams);
	const providerHintsStr =
		searchParams.get('provider_hint') ||
		searchParams.get('providerHints') ||
		consentData.provider_hint ||
		consentData.providerHints;

	if (!providerHintsStr) return getRecommendedProviders();

	const providerHints = {
		shown: [],
		hidden: []
	};
	const possibleSlugs = [
		...logins.filter((i) => !i.no_login).map((i) => i.slug),
		'email',
		'ethereum',
		'qrcode'
		// 'passkey'
	];
	let unknownSlugs = [];
	for (let provider of providerHintsStr.split(' ')) {
		if (provider && provider.endsWith('--')) {
			provider = provider.substring(0, provider.length - 2);

			if (!possibleSlugs.includes(provider)) {
				unknownSlugs.push(provider);
				continue;
			}

			providerHints.hidden = [...providerHints.hidden, provider];
			continue;
		}

		if (!possibleSlugs.includes(provider)) {
			unknownSlugs.push(provider);
			continue;
		}

		providerHints.shown = [...providerHints.shown, provider];
	}

	if (unknownSlugs.length) {
		console.warn(
			`Unsupported provider_hint values: ${unknownSlugs.join(
				', '
			)}\nPlease see https://www.hello.dev/docs/apis/wallet/#provider_hint for supported values.`
		);
	}

	return getRecommendedProviders(providerHints.shown, providerHints.hidden);
};

export {
	handleConsentResponse,
	saveAuthParams,
	logAuthParams,
	wizard,
	ERRORS,
	logProviderResPlausibleEvents,
	logReleaseFunnelEndPlausible,
	processProviderHintParam
};
