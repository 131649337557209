<script>
	import { slide } from 'svelte/transition';
	import { DEFAULT_MANAGED_LOGO, SLIDE_ANIM_MS } from '$src/constants.js';
	import Dropdown from '$lib/Dropdown.svelte';
	import ManagedProvider from '$lib/ManagedProvider.svelte';

	let {
		expanded = $bindable(false),
		label = '',
		login = false,
		showIcon = true,
		hasBackground = false,
		accountSelected = undefined,
		handleDropdown = undefined,
		managedEmailSuccess = () => {}
	} = $props();
</script>

<Dropdown
	dataTest="managed-provider-btn"
	ariaLabel={label}
	{expanded}
	{hasBackground}
	onclick={() => {
		if (handleDropdown) handleDropdown('managedProvider');
		else expanded = !expanded;
	}}
>
	<div class="flex h-12 w-full items-center justify-start gap-x-4 px-4">
		{#if showIcon}
			<span class="managed-rounded-square-sm">
				<img src={DEFAULT_MANAGED_LOGO} alt="logo" class="w-4.5 max-h-[18px] object-contain" />
			</span>
		{/if}
		<span class="block text-left" aria-hidden="true">
			{label}
		</span>
	</div>

	{#if expanded}
		<div
			class="z-30 flex w-full flex-col rounded-md p-4 text-left"
			transition:slide={{ duration: SLIDE_ANIM_MS }}
		>
			<ManagedProvider {login} {accountSelected} {managedEmailSuccess} />
		</div>
	{/if}
</Dropdown>
