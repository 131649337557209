<script>
	import { _, locale } from 'svelte-i18n';
	import { global } from '$src/state.svelte.js';
	import { slide } from 'svelte/transition';
	import { dedupe } from '$utils/common';
	import { putName } from '$utils/api.js';
	import { deleteName } from '$utils/api.js';
	import { SLIDE_ANIM_MS } from '$src/constants.js';
	import Modal from '$lib/modal/Modal.svelte';
	import VerifyName from '$lib/VerifyName.svelte';
	import RemoveButton from '$lib/button/RemoveButton.svelte';
	import RemoveIconButton from '$lib/button/RemoveIconButton.svelte';

	const addModal = $state({
		fullName: false,
		preferredName: false,
		firstName: false,
		lastName: false
	});

	const deleteModal = $state({
		fullName: false,
		preferredName: false,
		firstName: false,
		lastName: false
	});

	let addFullNameAjax = $state(false);
	async function addFullName(value) {
		addFullNameAjax = true;
		const { names } = await putName('name', value);
		if (names) {
			global.data.profile.names = names;
			addModal.fullName = false;
		}
		addFullNameAjax = false;
	}

	let addPreferredNameAjax = $state(false);
	async function addPreferredName(value) {
		addPreferredNameAjax = true;
		const { nicknames } = await putName('nickname', value);
		if (nicknames) {
			global.data.profile.nicknames = nicknames;
			addModal.preferredName = false;
		}
		addPreferredNameAjax = false;
	}

	let addFirstNameAjax = $state(false);
	async function addFirstName(value) {
		addFirstNameAjax = true;
		const { given_names } = await putName('given_name', value);
		if (given_names) {
			global.data.profile.given_names = given_names;
			addModal.firstName = false;
		}
		addFirstNameAjax = false;
	}

	let addLastNameAjax = $state(false);
	async function addLastName(value) {
		addLastNameAjax = true;
		const { family_names } = await putName('family_name', value);
		if (family_names) {
			global.data.profile.family_names = family_names;
			addModal.lastName = false;
		}
		addLastNameAjax = false;
	}

	let removeFullNameAjax = $state(false);
	async function removeFullName(index) {
		removeFullNameAjax = deleteModal.fullName;
		const { names } = await deleteName('name', index);
		if (names) {
			global.data.profile.names = names;
			deleteModal.fullName = false;
		}
		removeFullNameAjax = null;
	}

	let removePreferredNameAjax = $state(false);
	async function removePreferredName(index) {
		removePreferredNameAjax = deleteModal.preferredName;
		const { nicknames } = await deleteName('nickname', index);
		if (nicknames) {
			global.data.profile.nicknames = nicknames;
			deleteModal.preferredName = false;
		}
		removePreferredNameAjax = null;
	}

	let removeFirstNameAjax = $state(false);
	async function removeFirstName(index) {
		removeFirstNameAjax = deleteModal.firstName;
		const { given_names } = await deleteName('given_name', index);
		if (given_names) {
			global.data.profile.given_names = given_names;
			deleteModal.firstName = false;
		}
		removeFirstNameAjax = null;
	}

	let removeLastNameAjax = $state(false);
	async function removeLastName(index) {
		removeLastNameAjax = deleteModal.lastName;
		const { family_names } = await deleteName('family_name', index);
		if (family_names) {
			global.data.profile.family_names = family_names;
			deleteModal.lastName = false;
		}
		removeLastNameAjax = null;
	}
</script>

<div transition:slide={{ duration: SLIDE_ANIM_MS }} class="mt-4">
	<!-- Full Names -->
	<section data-test="name-section" class="py-2">
		<h3 class="text-sm opacity-80">
			{$_('Full Names')}
		</h3>

		{#if global.data.profile.names?.length}
			<ul class="mt-2 space-y-2">
				{#each dedupe(global.data.profile.names) as value, index (value)}
					<li
						data-test={`name-item-${index}`}
						class="relative flex flex-shrink-0 items-center"
						transition:slide={{ duration: SLIDE_ANIM_MS }}
					>
						<div class="inline-flex h-6 w-6 items-center">
							{#if global.data.profile.names.length > 1}
								<RemoveIconButton
									dataTest="remove-name-btn"
									onclick={() => (deleteModal.fullName = value)}
								/>
							{/if}
						</div>

						<span>{value}</span>

						{#if deleteModal.fullName && deleteModal.fullName === value}
							<Modal
								dataTest="name-confirm-deletion-modal"
								position="left"
								css="top-10 md:min-w-[22rem]"
								close={() => (deleteModal.fullName = null)}
							>
								<div class="flex flex-col items-center justify-between md:flex-row">
									<span>{$_('Confirm Deletion')}</span>
									<div class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2">
										<button
											data-test="cancel-deletion-name-btn"
											onclick={() => (deleteModal.fullName = null)}
											class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
										>
										<RemoveButton
											dataTest="confirm-deletion-name-btn"
											onclick={() => removeFullName(index)}
											isLoading={removeFullNameAjax === value}
											value="Delete"
										/>
									</div>
								</div>
							</Modal>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</section>

	<div class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}">
		<button
			data-test="add-name-btn"
			onclick={() => (addModal.fullName = true)}
			class="btn-background mb-1 h-6 px-2 text-sm">{$_('Add full name')}</button
		>

		{#if addModal.fullName}
			<Modal position="left" close={() => (addModal.fullName = false)}>
				<VerifyName type="name" addName={addFullName} ajaxRequest={addFullNameAjax} />
			</Modal>
		{/if}
	</div>

	<!-- Preferred Names -->
	<section data-test="nickname-section" class="py-2">
		<h3 class="text-sm opacity-80">
			{$_('Preferred Names')}
		</h3>

		{#if global.data.profile.nicknames?.length}
			<ul class="mt-2 space-y-2">
				{#each dedupe(global.data.profile.nicknames) as value, index (value)}
					<li
						data-test={`nickname-item-${index}`}
						class="relative flex flex-shrink-0 items-center"
						transition:slide={{ duration: SLIDE_ANIM_MS }}
					>
						<div class="inline-flex h-6 w-6 items-center">
							{#if global.data.profile.nicknames.length > 1}
								<RemoveIconButton
									dataTest="remove-nickname-btn"
									onclick={() => (deleteModal.preferredName = value)}
								/>
							{/if}
						</div>

						<span>{value}</span>

						{#if deleteModal.preferredName && deleteModal.preferredName === value}
							<Modal
								dataTest="nickname-confirm-deletion-modal"
								position="left"
								css="top-10 md:min-w-[22rem]"
								close={() => (deleteModal.preferredName = null)}
							>
								<div class="flex flex-col items-center justify-between md:flex-row">
									<span>{$_('Confirm Deletion')}</span>
									<div class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2">
										<button
											data-test="cancel-deletion-nickname-btn"
											onclick={() => (deleteModal.preferredName = null)}
											class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
										>
										<RemoveButton
											dataTest="confirm-deletion-nickname-btn"
											onclick={() => removePreferredName(index)}
											isLoading={removePreferredNameAjax === value}
											value="Delete"
										/>
									</div>
								</div>
							</Modal>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</section>

	<div class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}">
		<button
			data-test="add-nickname-btn"
			onclick={() => (addModal.preferredName = true)}
			class="btn-background mb-1 h-6 px-2 text-sm">{$_('Add preferred name')}</button
		>

		{#if addModal.preferredName}
			<Modal position="left" close={() => (addModal.preferredName = false)}>
				<VerifyName type="nickname" addName={addPreferredName} ajaxRequest={addPreferredNameAjax} />
			</Modal>
		{/if}
	</div>

	<!-- First Names -->
	<section data-test="given_name-section" class="py-2">
		<h3 class="text-sm opacity-80">
			{$_('First Names')}
		</h3>

		{#if global.data.profile.given_names?.length}
			<ul class="mt-2 space-y-2">
				{#each dedupe(global.data.profile.given_names) as value, index (value)}
					<li
						data-test="given_name-item-{index}"
						class="relative flex flex-shrink-0 items-center"
						transition:slide={{ duration: SLIDE_ANIM_MS }}
					>
						<div class="inline-flex h-6 w-6 items-center">
							{#if global.data.profile.given_names.length > 1}
								<RemoveIconButton
									dataTest="remove-given_name-btn"
									onclick={() => (deleteModal.firstName = value)}
								/>
							{/if}
						</div>

						<span>{value}</span>

						{#if deleteModal.firstName && deleteModal.firstName === value}
							<Modal
								dataTest="given_name-confirm-deletion-modal"
								position="left"
								css="top-10 md:min-w-[22rem]"
								close={() => (deleteModal.firstName = null)}
							>
								<div class="flex flex-col items-center justify-between md:flex-row">
									<span>{$_('Confirm Deletion')}</span>
									<div class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2">
										<button
											data-test="cancel-deletion-given_name-btn"
											onclick={() => (deleteModal.firstName = null)}
											class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
										>
										<RemoveButton
											dataTest="confirm-deletion-given_name-btn"
											onclick={() => removeFirstName(index)}
											isLoading={removeFirstNameAjax === value}
											value="Delete"
										/>
									</div>
								</div>
							</Modal>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</section>

	<div class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}">
		<button
			data-test="add-given_name-btn"
			onclick={() => (addModal.firstName = true)}
			class="btn-background mb-1 h-6 px-2 text-sm">{$_('Add first name')}</button
		>

		{#if addModal.firstName}
			<Modal position="left" close={() => (addModal.firstName = false)}>
				<VerifyName type="given-name" addName={addFirstName} ajaxRequest={addFirstNameAjax} />
			</Modal>
		{/if}
	</div>

	<!-- Last Names -->
	<section data-test="family_name-section" class="py-2">
		<h3 class="text-sm opacity-80">
			{$_('Last Names')}
		</h3>

		{#if global.data.profile.family_names?.length}
			<ul class="mt-2 space-y-2">
				{#each dedupe(global.data.profile.family_names) as value, index (value)}
					<li
						data-test="family_name-item-{index}"
						class="relative flex flex-shrink-0 items-center"
						transition:slide={{ duration: SLIDE_ANIM_MS }}
					>
						<div class="inline-flex h-6 w-6 items-center">
							{#if global.data.profile.family_names.length > 1}
								<RemoveIconButton
									dataTest="remove-family_name-btn"
									onclick={() => (deleteModal.lastName = value)}
								/>
							{/if}
						</div>

						<span>{value}</span>

						{#if deleteModal.lastName && deleteModal.lastName === value}
							<Modal
								dataTest="family_name-confirm-deletion-modal"
								position="left"
								css="top-10 md:min-w-[22rem]"
								close={() => (deleteModal.lastName = null)}
							>
								<div class="flex flex-col items-center justify-between md:flex-row">
									<span>{$_('Confirm Deletion')}</span>
									<div class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2">
										<button
											data-test="cancel-deletion-family_name-btn"
											onclick={() => (deleteModal.lastName = null)}
											class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
										>
										<RemoveButton
											dataTest="confirm-deletion-family_name-btn"
											onclick={() => removeLastName(index)}
											isLoading={removeLastNameAjax === value}
											value="Delete"
										/>
									</div>
								</div>
							</Modal>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</section>

	<div class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}">
		<button
			data-test="add-family_name-btn"
			onclick={() => (addModal.lastName = true)}
			class="btn-background mb-1 h-6 px-2 text-sm">{$_('Add last name')}</button
		>

		{#if addModal.lastName}
			<Modal position="left" close={() => (addModal.lastName = false)}>
				<VerifyName type="family-name" addName={addLastName} ajaxRequest={addLastNameAjax} />
			</Modal>
		{/if}
	</div>
</div>
