<script>
	import { _ } from 'svelte-i18n';
	import tippy from 'sveltejs-tippy';
	import { locale } from 'svelte-i18n';
	import { global } from '$src/state.svelte.js';
	import { notification } from '$src/stores.js';
	import { getDisplay } from '$utils/common.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { trimEthAddress, getWallet } from '$utils/ethereum.js';
	import {
		postLoginProvider,
		postLoginEth,
		postLoginEthChallenge,
		putMerge,
		deleteMerge
	} from '$utils/api.js';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import VerifyPhone from '$lib/VerifyPhone.svelte';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import PhoneIcon from '$lib/icon/PhoneIcon.svelte';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import FullPageModal from '$lib/modal/FullPageModal.svelte';

	let mergeAjax = $state(false);
	let ethereumProgressModal = $state();
	let ethereumProgressNotifs = $state([]);

	async function confirmMerge() {
		mergeAjax = true;
		await putMerge();
		hydrateData();
		global.mergeAccounts = null;
	}

	async function cancelMerge() {
		await deleteMerge();
		global.mergeAccounts = null;
	}

	// We need the modal to be hidden if redirect for login has started.
	// We do not want to set global.mergeAccounts to null because we still need the state
	// if post login redirect call api fails.
	let hideModal = $state(false);

	async function continueWithProvider(slug, body, server) {
		hideModal = true;
		global.spinner = true;
		const { redirect } = await postLoginProvider({
			slug,
			body,
			server
		});
		window.location.href = redirect;
	}

	async function contactLoginSuccess() {
		hydrateData();
	}

	function handleContactError() {
		notification.show($_('Something went wrong. Please try again later.'), 'error');
		global.mergeAccounts = null;
	}

	function hydrateData() {
		//tbd refactor
		global.spinner = true;
		sessionStorage.setItem('showWalletMergedNotification', true); //this gets removed in getProfile / getConsent / getInvite calls
		window.location.reload(); //gets latest state
	}

	async function continueWithEthereumExtension(info) {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign')
			}
		];
		if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Expected ({address})', {
						values: {
							address: trimEthAddress(info.login_hint)
						}
					}),
					type: 'error',
					status: address
				}
			];
			return;
		}
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address) {
		let challenge, signature;

		const res = await postLoginEth(address);
		logPlausibleEvent({ u: `/start/merge/ethereum/extension/${getWallet().slug}`, n: 'action' });
		challenge = res.challenge;
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				status: $_('Waiting to sign')
			}
		];

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: 'Merging wallets...'
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				notification.show($_("You've rejected the sign request"), 'error');
			} else {
				notification.show($_('Something went wrong. Please try again later.'), 'error');
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		await postLoginEthChallenge(body);
		await logPlausibleEvent({ u: `/merge/ethereum/extension/${getWallet().slug}`, n: 'action' });
		hydrateData();
	}
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e);
			}
		}}
	/>
{/if}

{#if !hideModal}
	<FullPageModal dataTest="merge-modal">
		{#if global.mergeAccounts?.subject}
			{@const { slug, user_name, wallet, mastodonServer, logo } = global.mergeAccounts.subject}
			<div
				data-test="merge-account"
				class="flex h-auto w-full flex-col items-start rounded-md bg-[#DCDCDC] p-3 dark:bg-[#505050]"
			>
				<div class="flex items-center justify-start">
					<!-- logo means its a managed -->
					{#if logo}
						<span class="managed-rounded-square-lg m-0">
							<img src={logo} alt="logo" class="mt-0 max-h-[36px] w-9 object-contain" />
						</span>
					{:else}
						<img
							src={wallet?.icon ? wallet.icon : `https://cdn.hello.coop/images/${slug}.svg`}
							class="max-h-[36px] w-9 {['apple', 'twitter', 'email', 'phone'].includes(slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
								? 'dark:invert-20 invert'
								: ''}"
							alt="{slug} logo"
						/>
					{/if}
					<div class="ml-3 flex flex-col items-start">
						{#if wallet?.name}
							{wallet.name}
						{:else}
							<span>{getDisplay(slug)}</span>
						{/if}
						<span>{mastodonServer ? `@${user_name}@${mastodonServer}` : user_name}</span>
					</div>
				</div>
			</div>
		{/if}

		<h1 class="mt-4 text-center text-xl font-medium">is linked to another wallet</h1>

		{#if global.mergeAccounts?.preferred?.length}
			{@const { slug, user_name, wallet, mastodonServer, logo } = global.mergeAccounts.preferred[0]}
			<div
				data-test="merge-account-2"
				class="mt-4 flex h-auto w-full flex-col items-start rounded-md bg-[#DCDCDC] p-3 dark:bg-[#505050]"
			>
				<div class="flex items-center justify-start">
					<!-- logo means its managed -->
					{#if logo}
						<span class="managed-rounded-square-lg m-0">
							<img src={logo} alt="logo" class="mt-0 max-h-[36px] w-9 object-contain" />
						</span>
					{:else}
						<img
							src={wallet?.icon ? wallet.icon : `https://cdn.hello.coop/images/${slug}.svg`}
							class="max-h-[36px] w-9 {['apple', 'twitter', 'email', 'phone'].includes(slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
								? 'dark:invert-20 invert'
								: ''}"
							alt="{slug} logo"
						/>
					{/if}
					<div class="ml-3 flex flex-col items-start">
						<span>
							{#if wallet?.name}
								{wallet.name}
							{:else}
								{getDisplay(slug)}
							{/if}
						</span>
						<span>{mastodonServer ? `@${user_name}@${mastodonServer}` : user_name}</span>
					</div>
				</div>
			</div>
		{/if}

		{#if global.mergeAccounts?.preferredSubject}
			<div class="mt-8 w-full">
				<span class="mb-2 block text-left text-xl font-medium">Merge with</span>
				{#if global.mergeAccounts.preferredSubject.slug === 'email'}
					<div
						data-test="email-verify-container"
						class="btn-border hover-none relative h-auto w-full rounded-md px-4"
					>
						<button class="flex h-16 w-full items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'mr-4 text-right'
									: 'ml-4 text-left'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{global.mergeAccounts.preferredSubject.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								email={global.mergeAccounts.preferredSubject.login_hint}
								login
								disabled
								success={contactLoginSuccess}
								error={handleContactError}
							/>
						</div>
					</div>
				{:else if global.mergeAccounts.preferredSubject.slug === 'phone'}
					<div
						data-test="phone-verify-container"
						class="btn-border hover-none relative h-auto w-full rounded-md px-4"
					>
						<button class="flex h-16 w-full items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'mr-4 text-right'
									: 'ml-4 text-left'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{global.mergeAccounts.preferredSubject.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								phone={global.mergeAccounts.preferredSubject.login_hint}
								login
								disabled
								success={contactLoginSuccess}
								error={handleContactError}
							/>
						</div>
					</div>
				{:else if global.mergeAccounts.preferredSubject.slug === 'ethereum'}
					<div class="btn-border hover-none relative h-auto w-full space-y-3 rounded-md p-3">
						<span class="mb-3 block text-left"
							>{global.mergeAccounts.preferredSubject.wallet?.name || 'Ethereum'}
							{#if global.mergeAccounts.preferredSubject.wallet?.display}
								<span
									use:tippy={{
										content: global.mergeAccounts.preferredSubject.login_hint,
										placement: 'top'
									}}
								>
									({global.mergeAccounts.preferredSubject.wallet?.display})
								</span>
							{/if}
						</span>
						{#if global.mergeAccounts.preferredSubject.slug === 'ethereum' && window.ethereum}
							<LoginProvider
								ethereum={() =>
									continueWithEthereumExtension(global.mergeAccounts.preferredSubject)}
								provider={global.mergeAccounts.preferredSubject}
								hideusername
								prefix="Continue with"
							/>
						{/if}
					</div>
				{:else}
					<LoginProvider
						login={true}
						onclick={() =>
							continueWithProvider(
								global.mergeAccounts.preferredSubject.slug,
								{
									login_hint: global.mergeAccounts.preferredSubject.login_hint
								},
								global.mergeAccounts.preferredSubject.slug === 'mastodon'
									? global.mergeAccounts.preferredSubject.mastodonServer
									: null
							)}
						provider={global.mergeAccounts.preferredSubject}
					/>
				{/if}
			</div>
			<!-- <div class="mt-8">
							<button
								data-test="current-preferred-provider"
								disabled={mergeAjax}
								onclick={() => continueWithProvider(global.mergeAccounts.preferredSubject.slug)}
								class="btn-background w-full rounded-md p-3 h-28 flex {mergeAjax
									? 'items-center justify-center'
									: 'flex-col items-start'}"
							>
								{#if mergeAjax}
									<svg
										class="animate-spin h-7 w-7"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
									>
										<circle
											class="opacity-25"
											cx="12"
											cy="12"
											r="10"
											stroke="currentColor"
											stroke-width="4"
										/>
										<path
											class="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										/>
									</svg>
								{:else}
									<span class="text-xl font-medium mb-2">Merge with</span>
									<div class="flex items-center justify-start">
										<img
											src={global.mergeAccounts.preferredSubject.wallet?.icon
												? global.mergeAccounts.preferredSubject.wallet?.icon
												: `https://cdn.hello.coop/images/${global.mergeAccounts.preferredSubject.slug}.svg`}
											class="w-9 max-h-[36px] {['apple', 'twitter', 'email', 'phone'].includes( //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers. 
												global.mergeAccounts.preferredSubject.slug
											)
												? 'invert dark:invert-20'
												: ''}"
											alt={global.mergeAccounts.preferredSubject.slug}
										/>
										<div class="flex flex-col items-start ml-3">
											{#if global.mergeAccounts.preferredSubject.wallet?.name}
												<span>{global.mergeAccounts.preferredSubject.wallet?.name}</span>
											{:else}
												<span>{getDisplay(global.mergeAccounts.preferredSubject.slug)}</span>
											{/if}

											{#if global.mergeAccounts.preferredSubject.mastodonServer}
												<span
													>@{global.mergeAccounts.preferredSubject.user_name}@{global.mergeAccounts
														.preferredSubject.mastodonServer}</span
												>
											{:else if global.mergeAccounts.preferredSubject.user_name}
												<span>{global.mergeAccounts.preferredSubject.user_name}</span>
											{/if}
										</div>
									</div>
								{/if}
							</button>
						</div> -->
		{/if}

		{#if global.mergeAccounts?.preferred?.length && global.data.preferred?.length}
			{@const { slug, user_name, mastodonServer, wallet } = global.data.preferred[0]}
			<div
				class="mt-8 flex h-auto w-full flex-col items-start rounded-md bg-[#DCDCDC] p-3 dark:bg-[#505050]"
			>
				<span class="mb-2 text-xl font-medium">Merge with</span>
				<div data-test="current-preferred-provider" class="flex items-center justify-start">
					<img
						src={wallet?.icon ? wallet.icon : `https://cdn.hello.coop/images/${slug}.svg`}
						class="max-h-[36px] w-9 {['apple', 'twitter', 'email', 'phone'].includes(slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
							? 'dark:invert-20 invert'
							: ''}"
						alt="{slug} logo"
					/>
					<div class="ml-3 flex flex-col items-start">
						{#if wallet?.name}
							{wallet.name}
						{:else}
							<span>{getDisplay(slug)}</span>
						{/if}
						<span>{mastodonServer ? `@${user_name}@${mastodonServer}` : user_name}</span>
					</div>
				</div>
				<span class="mx-auto my-4 text-center text-xl font-medium">by verifying</span>
				{#if global.mergeAccounts.preferred[0].slug === 'email'}
					<div
						data-test="email-verify-container"
						class="btn-border hover-none relative h-auto w-full rounded-md px-4"
					>
						<button class="flex h-16 w-full items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'mr-4 text-right'
									: 'ml-4 text-left'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{global.mergeAccounts.preferred[0].login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								email={global.mergeAccounts.preferred[0].login_hint}
								login
								disabled
								success={contactLoginSuccess}
								error={handleContactError}
							/>
						</div>
					</div>
				{:else if global.mergeAccounts.preferred[0].slug === 'phone'}
					<div
						data-test="phone-verify-container"
						class="btn-border hover-none relative h-auto w-full rounded-md px-4"
					>
						<button class="flex h-16 w-full items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'mr-4 text-right'
									: 'ml-4 text-left'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{global.mergeAccounts.preferred[0].login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								phone={global.mergeAccounts.preferred[0].login_hint}
								login
								disabled
								success={contactLoginSuccess}
								error={handleContactError}
							/>
						</div>
					</div>
				{:else if global.mergeAccounts.preferred[0].slug === 'ethereum'}
					<div class="btn-border hover-none relative h-auto w-full space-y-3 rounded-md p-3">
						<span class="mb-3 block text-left"
							>{global.mergeAccounts.preferred[0].wallet?.name || 'Ethereum'}
							{#if global.mergeAccounts.preferred[0].wallet?.display}
								<span
									use:tippy={{
										content: global.mergeAccounts.preferred[0].login_hint,
										placement: 'top'
									}}
								>
									({global.mergeAccounts.preferred[0].wallet?.display})
								</span>
							{/if}
						</span>
						{#if global.mergeAccounts.preferred[0].slug === 'ethereum' && window.ethereum}
							<LoginProvider
								ethereum={() => continueWithEthereumExtension(global.mergeAccounts.preferred[0])}
								provider={global.mergeAccounts.preferred[0]}
								hideusername
								prefix="Continue with"
							/>
						{/if}
					</div>
				{:else}
					<LoginProvider
						login={true}
						onclick={() =>
							continueWithProvider(
								global.mergeAccounts.preferred[0].slug,
								{
									login_hint: global.mergeAccounts.preferred[0].login_hint
								},
								global.mergeAccounts.preferred[0].slug === 'mastodon'
									? global.mergeAccounts.preferred[0].mastodonServer
									: null
							)}
						provider={global.mergeAccounts.preferred[0]}
						prefix="Continue with"
					/>
				{/if}
			</div>
		{/if}

		{#if !global.mergeAccounts?.preferred && !global.mergeAccounts?.preferredSubject && (!!global.data.preferred?.length || !!global.data.managed?.length)}
			{@const currentAccount =
				global.data?.preferred?.[0] ||
				global.data?.managed?.find((i) => global.data?.subjects?.includes(i.id))}
			<div class="mt-6 w-full">
				<button
					data-test="current-preferred-provider"
					disabled={mergeAjax}
					onclick={confirmMerge}
					class="btn-background flex h-28 w-full rounded-md p-3 {mergeAjax
						? 'items-center justify-center'
						: 'flex-col items-start'}"
				>
					{#if mergeAjax}
						<SpinnerIcon css="h-7 w-7" />
					{:else}
						<span class="mb-2 text-xl font-medium">{$_('Merge with')}</span>
						<div class="flex items-center justify-start">
							{#if currentAccount.logo}
								<span class="managed-rounded-square-lg m-0">
									<img
										src={currentAccount.logo}
										alt="logo"
										class="mt-0 max-h-[36px] w-9 object-contain"
									/>
								</span>
							{:else}
								<img
									src={currentAccount.wallet?.icon ||
										`https://cdn.hello.coop/images/${currentAccount.slug}.svg`}
									class="max-h-[36px] w-9 {['apple', 'twitter', 'email', 'phone'].includes(
										//These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
										currentAccount.slug
									)
										? 'dark:invert-20 invert'
										: ''}"
									alt={currentAccount.slug}
								/>
							{/if}
							<div class="ml-3 flex flex-col items-start">
								{#if currentAccount.wallet?.name}
									<span>{currentAccount.wallet?.name}</span>
								{:else}
									<span>{getDisplay(currentAccount.slug)}</span>
								{/if}

								{#if currentAccount.mastodonServer}
									<span>@{currentAccount.user_name}@{currentAccount.mastodonServer}</span>
								{:else if currentAccount.user_name}
									<span>{currentAccount.user_name}</span>
								{/if}
							</div>
						</div>
					{/if}
				</button>
			</div>
		{/if}

		<button
			onclick={cancelMerge}
			data-test="cancel-btn"
			type="button"
			class="mr-auto mt-6 inline-block text-left hover:underline focus:underline"
			>{$_('Cancel')}</button
		>
	</FullPageModal>
{/if}
