import { global } from '$src/state.svelte.js';

const moduleFetchErrors = [
	'Failed to fetch dynamically imported module', //chrome
	'Importing a module script failed', // firefox
	'error loading dynamically imported module' //safari
];

// this fn gets called onerror, onunhandledrejection, and directly
const handleUnrecoverableError = async (err) => {
	// onerror if sync error, unhandledrejection if async / promise error
	// .error = onerror, .reason = unhandledrejection, err = handleUnrecoverableError is called directly with JS Error obj
	const error = err?.error || err?.reason || err;
	const stack = error?.stack;
	if (!stack) {
		//errors thrown from our web app are guaranteed to have stack
		console.error('Error from third-party script', err);
		return;
	}
	const message = error?.message;
	const globalData = global.data; // response from GET /profile | GET /consent
	const body = {
		message,
		stack,
		state: {
			page: window.location.href,
			globalData,
			storage: {
				session: window.sessionStorage,
				local: window.localStorage
			},
			navigator: {
				ua: window.navigator.userAgent,
				languages: window.navigator.languages
			}
		}
	};
	// passed from the transport layer
	if (err.reason?.api) body.state.api = err.reason.api;

	// dont use helper api fn here
	// we dont want it to be recursive (enter infinite loop) if something fails
	const postError = async (_body) => {
		try {
			await fetch('/api/v1/client/error', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(_body)
			});
		} catch (err) {
			console.error('failed to post error to slack', err);
		}
	};

	// handle module fetch fail errors
	const moduleFetchFailed = message && moduleFetchErrors.some((msg) => message.includes(msg));
	if (moduleFetchFailed) {
		// if module fetch fails, try to fetch version and cloudfront pop response header to report to slack
		try {
			const res = await fetch('/api/v1/version');
			if (!res.ok) throw res;
			const headers = res.headers;
			const json = await res.json();
			body.state.version = {
				version: json?.VERSION || 'Could not get version response',
				'x-amz-cf-pop': headers.get('x-amz-cf-pop') || 'Could not get pop response header'
			};
		} catch (err) {
			body.state.version = 'Failed to fetch version';
			console.error('Failed to fetch version', err);
		}
		postError(body);
		const tryAgain = window.confirm(
			'One or more resources in this app failed to load. Would you like to try reloading the page?'
		);
		if (tryAgain) {
			await postError({ ...body, stack: 'User opted to reload page - ' + stack });
			return window.location.reload();
		} else {
			return await postError({ ...body, stack: 'User opted not to reload page - ' + stack });
		}
	}

	postError(body);
};

export { handleUnrecoverableError };
