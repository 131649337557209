import { replace as replaceRoute } from 'svelte-spa-router';
import { readTranslatedKey } from '$utils/i18n';
import * as sort from '$utils/sort';
import { getDisplay } from '$utils/common.js';
import { logPlausibleEvent } from '$utils/plausible.js';

const addUnverifiedSujectsToAccounts = (profileData) => {
	if (profileData.profile?.unverified_emails?.length) {
		for (const email of profileData.profile.unverified_emails) {
			profileData.profile.accounts.push({
				id: `email::${email}`,
				user_name: email,
				display: email,
				slug: 'email',
				preferred: false,
				verifiedAt: false
			});
		}
	}

	if (profileData.profile?.unverified_phones?.length) {
		for (const phone of profileData.profile.unverified_phones) {
			profileData.profile.accounts.push({
				id: `phone::${phone}`,
				user_name: phone,
				display: phone,
				slug: 'phone',
				preferred: false,
				verifiedAt: false
			});
		}
	}
};

const updateAccounts = (profileData) => {
	//add sortrank, sortlabel and unverified accounts to accounts array
	addUnverifiedSujectsToAccounts(profileData);
	profileData.profile.accounts = sort.addSortRank(profileData.profile.accounts);
	profileData.profile.accounts = sort.addSortLabel(profileData.profile.accounts);

	return profileData;
};

const wizard = (profile) => {
	const hasPreferred = profile?.preferred?.length;
	const preferredLoggedIn = profile?.isPersonalLoggedIn;
	const isLoggedIn = profile?.isPersonalLoggedIn || profile?.isManagedLoggedIn;
	const currentWizardStage = localStorage.getItem('currentWizardStage');
	const isManagedWizard =
		profile?.verifyManagedEmail || profile?.chooseWhoManages || profile?.chooseManagedLogo;

	if (isManagedWizard || (isLoggedIn && !hasPreferred)) {
		if (!currentWizardStage && !profile?.actions?.doneWizardAt)
			return replaceRoute('/wizard/welcome');

		if (profile?.verifyManagedEmail) return replaceRoute('/wizard/email');

		if (profile?.chooseWhoManages || profile?.chooseManagedLogo)
			return replaceRoute('/wizard/managed');

		if (isLoggedIn && !hasPreferred) return replaceRoute('/wizard/preferredprovider');
	}

	if (
		((hasPreferred && preferredLoggedIn) || (!hasPreferred && isLoggedIn)) &&
		!profile.actions?.doneWizardAt
	) {
		//Determine next step in wizard after linking provider
		const WIZARD_STAGES = [
			'welcome',
			'preferredprovider',
			'status',
			'recoveryprovider',
			'incomplete',
			'upgrade'
		];
		if (WIZARD_STAGES.includes(currentWizardStage)) {
			// get our current state of preferred and recovery providers
			const preferred = profile.profile?.accounts?.find((i) => i.preferred);
			const UPGRADEABLE_PROVIDERS = ['email', 'phone'];
			const NON_RECOMMENDED_PROVIDERS = [...UPGRADEABLE_PROVIDERS, 'ethereum'];
			const isUpgradable = UPGRADEABLE_PROVIDERS.includes(preferred?.slug);
			const socialAccountLinked = profile.profile?.accounts.filter(
				(i) => !NON_RECOMMENDED_PROVIDERS.includes(i.slug) && !i.managed
			).length;
			const recoveries = profile.profile?.accounts.filter((i) => i.recovery && !i.preferred);
			if (recoveries?.length >= 2) {
				// we have all the recoveries we need
				if (isUpgradable && socialAccountLinked) {
					return replaceRoute('/wizard/upgrade');
				} else {
					return replaceRoute('/wizard/status');
				}
			}
			return replaceRoute('/wizard/' + currentWizardStage);
		}
		return replaceRoute('/wizard/' + WIZARD_STAGES[0]);
	}
	return false;
};

const makeAuthorityNotification = (account, text) => {
	if (account.slug === 'ethereum') {
		return readTranslatedKey(text, {
			values: {
				provider: account.wallet?.name,
				label: account.user_name || ''
			}
		});
	} else if (account.slug === 'email') {
		return readTranslatedKey(text, {
			values: {
				provider: readTranslatedKey('Email'),
				label: account.user_name || ''
			}
		});
	} else if (account.slug === 'phone') {
		return readTranslatedKey(text, {
			values: {
				provider: readTranslatedKey('Phone'),
				label: account.user_name || ''
			}
		});
	} else {
		return readTranslatedKey(text, {
			values: {
				provider: getDisplay(account.slug),
				label: account.user_name || ''
			}
		});
	}
};

function logProviderResPlausibleEvents(profile, params) {
	//End of Email Upgrade Funnel
	//email upgrade funnel state is valid and not already sent + log in success
	if (sessionStorage.email_upgrade_funnel === 'email_upgrade_start' && profile.isPersonalLoggedIn) {
		const email_domain = profile?.preferred?.[0]?.user_name?.split('@')[1];
		logPlausibleEvent({
			n: 'Email Upgrade Success',
			p: { email_domain, slug: params.get('provider') },
			u: '/'
		});
		sessionStorage.removeItem('email_upgrade_funnel');
	}

	//Wizard Funnel
	const isInWizard = !profile?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
	if (isInWizard) {
		const preferred = profile?.preferred?.[0]?.slug;
		let welcome_email_app;
		if (sessionStorage.welcome_app_info) {
			try {
				if (sessionStorage.welcome_app_info) {
					try {
						welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
					} catch (err) {
						console.error(err);
					}
				}
			} catch (err) {
				console.error(err);
			}
		}
		const recovery_1 = profile?.recovery?.[0]?.slug;
		const recovery_2 = profile?.recovery?.[1]?.slug;
		if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
			logPlausibleEvent({
				n: 'Wiz Recovery 1 Success',
				p: { preferred, welcome_email_app, recovery_1 },
				u: '/'
			});
			sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success');
		} else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
			logPlausibleEvent({
				n: 'Wiz Recovery 2 Success',
				p: { preferred, welcome_email_app, recovery_1, recovery_2 },
				u: '/'
			});
			sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success');
		}
	}
}

export {
	addUnverifiedSujectsToAccounts,
	updateAccounts,
	wizard,
	makeAuthorityNotification,
	logProviderResPlausibleEvents
};
