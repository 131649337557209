<script>
	let { rotate = false, hover = false, css = '' } = $props();
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	class="h-4.5 transform opacity-80 {css}"
	class:rotate-180={rotate}
	fill="none"
	viewBox="0 0 24 24"
	stroke="currentColor"
	stroke-width={hover ? '3' : '2'}
>
	<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
</svg>
