<script>
	let { css = '' } = $props();
</script>

<svg class={css} viewBox="0 0 651 634" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M237.333 1.06607C184.933 5.73273 136.533 39.7328 114.667 87.0661C96.7999 125.999 95.9999 169.866 112.667 209.866C121.467 230.933 140 254.933 158.267 269.066C210.133 308.933 279.867 311.466 334.133 275.333C373.2 249.333 397.867 205.866 400.267 158.666C401.867 127.466 394.933 99.7328 378.933 72.6661C370.8 58.7994 349.067 35.7327 335.467 26.6661C318 14.9327 295.867 5.9994 276.4 2.66607C268.267 1.33273 245.333 0.266065 237.333 1.06607Z"
		fill="currentColor"
	/>
	<path
		d="M511.466 186C498.133 189.067 490.133 191.867 479.6 197.467C438.533 219.2 414.933 261.733 417.6 309.067C419.6 345.733 443.066 383.067 475.733 401.333C482.8 405.333 483.333 405.867 483.333 410.4C483.333 413.2 483.6 453.333 483.733 499.6L484 584L509.066 608.933L534 634L575.6 592.267L617.333 550.667L592.4 525.733L567.333 500.667L592.266 475.733L617.333 450.667L596.8 430.133L576.266 409.6L585.2 405.333C603.333 396.667 622 380 632.933 362.667C645.2 343.067 650.133 325.067 650 300.533C650 278.8 647.333 267.2 638.133 248.667C623.6 219.2 598.4 198 566.666 188.667C551.6 184.133 525.2 182.933 511.466 186ZM544.8 236.267C551.6 238.267 560 245.467 563.733 252.533C567.733 260.133 567.733 274.4 563.733 282.133C557.733 293.333 546.8 300 534 300C506.533 300 491.2 269.467 507.733 247.733C516.266 236.4 530.8 232 544.8 236.267Z"
		fill="currentColor"
	/>
	<path
		d="M170.667 352.799C95.9999 364.265 34.3999 416.265 10.7999 487.599C2.53324 512.665 1.73324 519.332 1.06657 570.265L0.399902 617.332H217.2H434V525.999V434.665L418.933 419.599C403.733 404.532 393.333 390.532 386.267 375.732C382.667 368.265 382.533 367.999 372 363.865C359.2 358.799 340.8 353.999 326.8 352.132C310.933 349.865 185.867 350.399 170.667 352.799Z"
		fill="currentColor"
	/>
</svg>
