<script>
	let { hover = false } = $props();
</script>

<div class="relative inline-flex items-center justify-center">
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="z-10 h-5 w-5 text-red-500"
		viewBox="0 0 20 20"
		fill="currentColor"
	>
		<path
			fill-rule="evenodd"
			d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
			clip-rule="evenodd"
		/>
	</svg>
	{#if hover}
		<div class="absolute z-0 block h-5 w-5 rounded-full border-[3px] border-red-500"></div>
	{/if}
</div>
