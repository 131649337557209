<script>
	import { notification } from '$src/stores.js';
	import { slide } from 'svelte/transition';
	import { clickOutside } from '$utils/actions.js';
	import { SLIDE_ANIM_MS } from '$src/constants';
	import CloseIcon from '$lib/icon/CloseIcon.svelte';

	let { css = '' } = $props();
</script>

<div
	class="mx-auto flex w-full flex-shrink-0 items-center justify-center p-1 py-2 text-sm {css}"
	style="z-index: 60;"
	data-test={$notification.type === 'success' ? 'success-notification' : 'error-notification'}
	class:bg-green-500={$notification.type === 'success'}
	class:bg-red-500={$notification.type === 'error'}
	class:text-charcoal={$notification.type === 'success'}
	class:text-white={$notification.type === 'error'}
	transition:slide={{ duration: SLIDE_ANIM_MS }}
	use:clickOutside={() => notification.clear()}
>
	<div role="alert" class="relative flex w-full items-center justify-between">
		<div class="mx-auto flex w-3/4 items-center justify-center text-center md:w-full">
			<span class="inline-flex items-center">
				<span>{@html $notification.text || ''}</span>
			</span>
		</div>
		<div class="absolute right-4 inline-flex items-center justify-end">
			<button
				aria-label="Close"
				data-test="close-notification"
				onclick={() => notification.clear()}
				class="group"
			>
				<CloseIcon />
			</button>
		</div>
	</div>
</div>
