export const API_BASE = '/api/v1';
export const IS_PROD = window.location.hostname === 'wallet.hello.coop';
export const HELLO_APPS = ['hello_console', 'hello_quick_start'];
export const FALLBACK_APP_ICON_DARK = 'https://cdn.hello.coop/images/fallback-app-icon.png';
export const FALLBACK_APP_ICON_LIGHT = 'https://cdn.hello.coop/images/fallback-app-icon-light.png';
export const NAMES_MAP = {
	name: 'Full name',
	nickname: 'Preferred name',
	given_name: 'First name',
	family_name: 'Last name'
};

// Session
export const SESSION_EXPIRE_MS = 270 * 1000; // 4.5min - Counts down to SESSION_EXPIRE_SHOW_MODAL_MS
export const SESSION_EXPIRE_SHOW_MODAL_MS = 30 * 1000; // 30sec
export const SESSION_MODAL_WARNING_MS = 5000; // 5 sec
export const CLIENT_REQUEST_TIMEOUT_MS = 3 * 60 * 1000; // 3min

// Managed
export const DEFAULT_MANAGED_LOGO = 'https://cdn.hello.coop/images/default-org.png';
export const DEFAULT_MANAGED_LOGO_NAME = 'default-org.png';
export const DEFAULT_PERSONAL_LOGO = 'https://cdn.hello.coop/images/default-personal.svg';
export const RECOMMENDED_MANAGED_LOGO_SIZE = 72;

// Animations
export const FLIP_ANIM_MS = 650;
export const FADE_ANIM_DELAY_MS = 50;
export const SLIDE_ANIM_MS = 250;
