<script>
	import { onMount } from 'svelte';
	import routes from './index/index.js';
	import Router, { replace as replaceRoute } from 'svelte-spa-router';
	import { _, locale, isLoading } from 'svelte-i18n';
	import { deleteLogin } from '$utils/api.js';
	import { handleUnrecoverableError } from '$utils/errors.js';
	import { global } from '$src/state.svelte.js';
	import { countdown, notification } from '$src/stores.js';
	import { SESSION_EXPIRE_SHOW_MODAL_MS } from '$src/constants.js';
	import SessionTimerModal from '$lib/modal/SessionTimerModal.svelte';
	import MergeModal from '$lib/modal/MergeModal.svelte';
	import UpgradePreferredModal from '$lib/modal/UpgradePreferredModal.svelte';
	import ProfileErrorModal from '$lib/modal/error/ProfileErrorModal.svelte';

	let showSessionExpiredModal = $state(false);

	onMount(() => {
		//Remove load spinner in HTML page
		document.getElementById('load-spinner')?.remove();
		//Show Svelte spinner
		global.spinner = true;
	});

	async function logout() {
		await deleteLogin();
		global.data = {}; // will be refetched in login page
		replaceRoute('/login');
		notification.show($_('You have been logged out'), 'error');
	}

	$effect(() => {
		// if (!IS_PROD) console.log('[debug] session:', $countdown);

		if ($countdown <= SESSION_EXPIRE_SHOW_MODAL_MS / 1000) {
			showSessionExpiredModal = true;
			if ($countdown <= 0) {
				countdown.clear();
				logout().finally(() => (showSessionExpiredModal = false));
			}
		}

		if ($locale && $locale.startsWith('ar')) {
			document.dir = 'rtl';
		} else {
			document.dir = 'ltr';
		}
	});
</script>

<svelte:window onerror={handleUnrecoverableError} onunhandledrejection={handleUnrecoverableError} />

{#if !$locale || $isLoading || global.spinner}
	<div class="spinner"></div>
{/if}

{#if $locale && !$isLoading}
	<div class="flex h-full flex-col">
		{#if global.unrecoverableError}
			<ProfileErrorModal />
		{/if}

		{#if global.mergeAccounts}
			<MergeModal />
		{/if}

		{#if global.upgradePreferred}
			<UpgradePreferredModal />
		{/if}

		{#if showSessionExpiredModal}
			<SessionTimerModal close={() => (showSessionExpiredModal = false)} />
		{/if}

		<Router {routes} />
	</div>
{/if}
