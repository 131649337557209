import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
import duration from 'dayjs/plugin/duration';

import 'dayjs/locale/ar';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/hi';
import 'dayjs/locale/de';
import 'dayjs/locale/pt';

dayjs.extend(preParsePostFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);

export default dayjs;
