<script>
	import { locale, _ } from 'svelte-i18n';
	import logins from '$svr/providers/logins.json';
	import { global } from '$src/state.svelte.js';
	import { postLoginProvider, postLinkProvider } from '$utils/api.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import ManagedProviderDropdown from '$lib/ManagedProviderDropdown.svelte';
	import QRCodeIcon from '$lib/icon/QRCodeIcon.svelte';

	let {
		prefix = '',
		login = false,
		cryptoWallets = true,
		showInstagram = false,
		showManagedLogin = true,
		ignoreProviders = [],
		continueWithQRCodeAjax = false,
		expandManagedProvider = $bindable(false),
		accountSelected = undefined,
		showQRLogin = false,
		choosePreferred = undefined,
		children,
		qrcode = () => {},
		managedEmailSuccess = () => {},
		ethereum = () => {}
	} = $props();

	async function continueWithProvider(slug, server) {
		global.spinner = true;
		if (login) {
			const res = await postLoginProvider({
				slug,
				server,
				body: { accountSelected, choosePreferred }
			});
			//error if !res.redirect is thrown at postLoginProvider

			//New User Release Funnel
			if (window.isWalletAuthorizeApp) {
				const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
					sessionStorage.az_release_funnel
				);
				const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
				//session funnel state is valid and not already sent + is authorize app
				if (
					indexOfCurrentFunnelStep !== -1 &&
					indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
					window.isWalletAuthorizeApp
				) {
					const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'client_id'
					);
					const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'redirect_uri'
					);
					let redirect;
					try {
						redirect = new URL(redirect_uri)?.hostname;
					} catch (err) {
						console.error(err);
					}
					//recommended_provider is always false in this case since this component only renders for below fold (non-recommended) providers
					await logPlausibleEvent({
						n: 'AZ Login Start',
						p: { client_id, provider: slug, recommended_provider: false, redirect },
						u: '/login'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_start');
				}
			}
			window.location.href = res.redirect;
		} else {
			const res = await postLinkProvider({
				slug,
				server
			});
			//error if !res.redirect is thrown at postLinkProvider

			//Wizard Funnel
			const isInWizard = !global.data?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
			if (window.isWalletApp && isInWizard) {
				const preferred = global.data?.preferred?.[0]?.slug;
				let welcome_email_app;
				if (sessionStorage.welcome_app_info) {
					if (sessionStorage.welcome_app_info) {
						try {
							welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
						} catch (err) {
							console.error(err);
						}
					}
				}
				const recovery_1 = global.data?.recovery?.[0]?.slug;
				if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
					await logPlausibleEvent({
						n: 'Wiz Recovery 1 Start',
						p: { preferred, welcome_email_app, recovery_1: slug },
						u: '/wizard/recoveryprovider'
					});
					sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start');
				} else if (recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery_1_success') {
					await logPlausibleEvent({
						n: 'Wiz Recovery 2 Start',
						p: { preferred, welcome_email_app, recovery_1, recovery_2: slug },
						u: '/wizard/recoveryprovider'
					});
					sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start');
				}
			}

			window.location.href = res.redirect;
		}
	}
</script>

<div class="space-y-2">
	{#if cryptoWallets && !ignoreProviders.includes('ethereum')}
		{#if window.ethereum?.isMetaMask}
			<LoginProvider
				onclick={ethereum}
				provider={{ display: 'MetaMask', slug: 'metamask' }}
				prefix={!login &&
				global.data?.profile?.accounts?.filter(
					(i) => i.wallet?.name === 'MetaMask' && i.recovery && !i.managed
				).length
					? `${prefix} additional`
					: prefix}
			/>
		{:else if window.ethereum}
			<LoginProvider
				onclick={ethereum}
				provider={{ display: 'Ethereum', slug: 'ethereum' }}
				prefix={!login &&
				global.data?.profile?.accounts?.filter(
					(i) => i.slug === 'ethereum' && i.recovery && !i.managed
				).length
					? `${prefix} additional {provider}`
					: `${prefix} {provider}`}
			/>
		{/if}
	{/if}

	{#if showManagedLogin && !ignoreProviders.includes('managed')}
		<ManagedProviderDropdown
			label={global.data?.profile?.accounts?.filter(
				(i) => i.slug === 'managed' && i.recovery && !i.managed
			).length
				? $_(prefix + ' additional Managed Provider')
				: $_(prefix + ' Managed Provider')}
			bind:expanded={expandManagedProvider}
			{managedEmailSuccess}
			{login}
			{accountSelected}
			hasBackground={true}
		/>
	{/if}

	{#each logins.filter((i) => !login || (login && !i.no_login)) as provider}
		{#if !ignoreProviders.includes(provider.slug)}
			<!-- Show Instagram only in /mastodon -->
			{#if provider.slug !== 'instagram' || (provider.slug === 'instagram' && !login && showInstagram)}
				<!-- Show Mastodon and Twitter at top in /mastodon -->
				{#if provider.slug !== 'mastodon' || provider.slug === 'mastodon'}
					{#if provider.slug !== 'twitter' || provider.slug === 'twitter'}
						{#if provider.slug !== 'zoho'}
							<LoginProvider
								{login}
								onclick={(e) => continueWithProvider(provider.slug, e)}
								{provider}
								prefix={!login &&
								global.data?.profile?.accounts?.filter(
									(i) => i.slug === provider.slug && i.recovery && !i.managed
								).length
									? `${prefix} additional`
									: prefix}
							/>
						{/if}
					{/if}
				{/if}
			{/if}
		{/if}
		<!-- Show email & phone after Google -->
		{#if provider.slug === 'google'}
			{@render children?.()}
		{/if}
	{/each}

	{#if login && showQRLogin && !global.isRemoteAuthClient && !ignoreProviders.includes('qrcode')}
		<button
			data-test="qr-btn"
			class="btn-background group relative flex h-12 w-full items-center justify-start px-4"
			onclick={qrcode}
			disabled={continueWithQRCodeAjax}
		>
			{#if continueWithQRCodeAjax}
				<SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
			{:else}
				<QRCodeIcon css="h-4.5 opacity-80" />

				<span class="{$locale && $locale.startsWith('ar') ? 'mr-4' : 'ml-4'} truncate">
					{$_('Continue by scanning QR Code')}
				</span>

				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="group-hover:stroke-3 h-4.5 ml-auto transform stroke-2 text-white opacity-80 dark:text-[#d4d4d4]"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
				</svg>
			{/if}
		</button>
	{/if}
</div>
