<script>
	import dayjs from '$utils/day.js';
	import { _, locale } from 'svelte-i18n';
	import { global } from '$src/state.svelte.js';
	import { putName } from '$utils/api.js';
	import CloseIcon from '$lib/icon/CloseIcon.svelte';
	import VerifyName from '$lib/VerifyName.svelte';
	import Modal from '$lib/modal/Modal.svelte';
	import EditIcon from '$lib/icon/EditIcon.svelte';

	let { editMode = false, toggleEdit = () => {} } = $props();

	let showAddNameModal = $state(false);

	let addFullNameAjax = $state(false);
	async function addFullName(value) {
		addFullNameAjax = true;
		const { names } = await putName('name', value);
		global.data.profile.names = names;
		showAddNameModal = false;
		addFullNameAjax = false;
	}
</script>

<section class="z-[35] w-full bg-[#ebebeb] dark:bg-[#252525]">
	<!-- saturate(180%) blur(20px) -->
	<div class="container mx-auto flex max-w-2xl items-center justify-between px-3 py-4">
		<div class="flex flex-col items-start">
			{#if global.data.profile?.names?.length}
				<h1 class="text-xl font-medium">{global.data.profile.names[0]}</h1>
			{:else}
				<div class="relative">
					<button
						onclick={() => (showAddNameModal = true)}
						class="btn-background mb-1 h-6 px-2 text-sm">{$_('Add full name')}</button
					>

					{#if showAddNameModal}
						<Modal css="top-12 !w-[16rem]" position="left" close={() => (showAddNameModal = false)}>
							<VerifyName type="name" addName={addFullName} ajaxRequest={addFullNameAjax} />
						</Modal>
					{/if}
				</div>
			{/if}
			<h2 class="text-sm opacity-80">
				{$_('Member since {date}', {
					values: {
						date: dayjs(global.data.profile.createdAt).locale($locale).format('MMMM DD, YYYY')
					}
				})}
			</h2>
		</div>

		<button
			data-test="profile-edit-btn"
			onclick={toggleEdit}
			class="btn-border inline-flex h-8 w-24 items-center justify-center gap-x-1 truncate rounded-full px-2"
		>
			{#if editMode}
				<CloseIcon />
				<span class="truncate">{$_('Close')}</span>
			{:else}
				<EditIcon />
				<span class="truncate">{$_('Edit')}</span>
			{/if}
		</button>
	</div>
</section>
