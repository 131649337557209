<script>
	import { deleteCookies } from '$utils/api.js';
	import FullPageModal from '$src/lib/modal/FullPageModal.svelte';

	async function startOver() {
		try {
			await deleteCookies();
		} catch (err) {
			console.error(err);
		}
		window.location.href = window.location.origin + '/';
	}
</script>

<FullPageModal dataTest="unrecoverable-error-modal" modalCss="sm:max-w-sm">
	<div class="mx-auto w-full text-center">
		<h1 class="text-lg font-medium text-red-500">The server got confused</h1>
		<button
			onclick={startOver}
			class="btn-background mt-5 inline-flex w-full items-center justify-center space-x-2"
		>
			<span>Let's start over</span>
		</button>
	</div>
</FullPageModal>
