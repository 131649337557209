<script>
	import { _, locale } from 'svelte-i18n';
	import * as sort from '$utils/sort';
	import dayjs from '$utils/day.js';
	import { global } from '$src/state.svelte.js';
	import { DEFAULT_MANAGED_LOGO, DEFAULT_PERSONAL_LOGO, SLIDE_ANIM_MS } from '$src/constants.js';
	import { getProfile, deleteApplication, deleteManagedApplication } from '$utils/api.js';
	import { slide } from 'svelte/transition';
	import { dedupe } from '$utils/common';
	import { updateAccounts } from '$utils/profile';
	import Modal from '$lib/modal/Modal.svelte';
	import RemoveButton from '$lib/button/RemoveButton.svelte';
	import RemoveIconButton from '$lib/button/RemoveIconButton.svelte';

	let { editMode = false } = $props();

	let confirmManagedApplicationDelete = $state();
	let confirmApplicationDelete = $state();

	let removeApplicationAjax = $state(false);
	async function removeApplication() {
		removeApplicationAjax = confirmApplicationDelete;
		await deleteApplication(confirmApplicationDelete);
		global.data = await getProfile();
		global.data = updateAccounts(global.data); //add sortrank, sortlabel and unverified accounts to accounts array
		confirmApplicationDelete = null;
		removeApplicationAjax = null;
	}

	let removeManagedApplicationAjax = $state(false);
	async function removeManagedApplication() {
		removeManagedApplicationAjax = confirmManagedApplicationDelete;
		await deleteManagedApplication(confirmManagedApplicationDelete);
		global.data = await getProfile();
		global.data = updateAccounts(global.data); //add sortrank, sortlabel and unverified accounts to accounts array
		confirmManagedApplicationDelete = null;
		removeManagedApplicationAjax = null;
	}
</script>

<section id="applications" class="mt-10">
	<h2 class="text-lg font-medium">{$_('Applications')}</h2>

	{#if global.data.profile.applications?.length}
		<div class="pl-6">
			<h3
				class="bg-charcoal mt-3 inline-flex h-12 w-full items-center overflow-hidden rounded-md bg-opacity-10 px-4 dark:bg-opacity-100"
			>
				<span class="managed-rounded-square-sm bg-transparent">
					<img
						src={DEFAULT_PERSONAL_LOGO}
						class="w-4.5 max-h-[18px] object-contain dark:invert"
						alt="personal account application releases"
					/>
				</span>
				<span class="ml-2 px-0.5">{$_('Personal')}</span>
			</h3>
		</div>
		<ul class="divide-charcoal/25 divide-y pl-6 dark:divide-white/25">
			{#each dedupe([...global.data.profile.applications], 'id').sort(sort.apps) as application (application.id)}
				<li
					class="relative flex items-end px-3.5 py-3"
					transition:slide={{ duration: SLIDE_ANIM_MS }}
				>
					{#if confirmApplicationDelete && confirmApplicationDelete === application.id}
						<Modal
							position="left"
							css="top-10 md:min-w-[22rem]"
							close={() => (confirmApplicationDelete = null)}
						>
							<div class="flex flex-col items-center justify-between md:flex-row">
								<span>{$_('Confirm Action')}</span>
								<div class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2">
									<button
										onclick={() => (confirmApplicationDelete = null)}
										class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
									>
									<RemoveButton
										onclick={removeApplication}
										isLoading={removeApplicationAjax === application.id}
										value="Forget"
									/>
								</div>
							</div>
						</Modal>
					{/if}

					<div class="mb-7 w-2 flex-shrink-0">
						{#if editMode}
							<RemoveIconButton onclick={() => (confirmApplicationDelete = application.id)} />
						{/if}
					</div>

					<div class="inline-flex h-6 w-6 flex-shrink-0 items-center justify-center"></div>

					<div
						class="{$locale && $locale.startsWith('ar')
							? 'mr-2 items-start'
							: 'ml-2'} flex flex-col"
					>
						{#if application.image_uri || application.dark_image_uri}
							<picture>
								<source
									srcset={application.dark_image_uri || application.image_uri}
									media="(prefers-color-scheme: dark)"
								/>
								<img
									src={application.image_uri}
									alt={application.name}
									class="max-h-[1.8rem] max-w-[8rem] object-contain object-left"
								/>
							</picture>
						{/if}

						<span class="mt-1">
							{application.name}
						</span>

						<span class="text-xs italic">
							{$_('First login {date}', {
								values: {
									date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
								}
							})}

							{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country}
								•
								{application.firstRelease?.location?.City
									? `${application.firstRelease?.location?.City},`
									: ''}
								{application.firstRelease?.location?.Country}
							{/if}
						</span>
						<span class="text-xs italic">
							{#if application.lastRelease?.at}
								{$_('Last login {date}', {
									values: {
										date: dayjs(application.lastRelease?.at).locale($locale).fromNow()
									}
								})}
							{:else}
								{$_('Last login {date}', {
									values: {
										date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
									}
								})}
							{/if}

							{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country || application.lastRelease?.location?.City || application.lastRelease?.location?.Country}
								•
								{application.lastRelease?.location.City
									? `${application.lastRelease?.location?.City},`
									: `${application.firstRelease?.location?.City},`}
								{application.lastRelease?.location?.Country ||
									application.firstRelease?.location?.Country}
							{/if}
						</span>
					</div>
				</li>
			{/each}
		</ul>
	{/if}

	{#if global.data.profile.managedApplications?.length}
		<div class="mb-4 pl-6">
			{#each global.data.profile.managedApplications as managedApplication (managedApplication.subject.id)}
				<h3
					class="bg-charcoal mt-3 inline-flex h-12 w-full items-center overflow-hidden rounded-md bg-opacity-10 px-4 dark:bg-opacity-100"
				>
					<span class="managed-rounded-square-sm">
						<img
							src={managedApplication.subject.logo || DEFAULT_MANAGED_LOGO}
							class="w-4.5 max-h-[18px] object-contain"
							alt={managedApplication.subject.user_name}
						/>
					</span>
					<!-- <span
						class="flex-shrink-0 bg-charcoal dark:bg-transparent w-10 h-10 flex items-center justify-center"
					>
					</span> -->
					<span class="ml-2 px-0.5">{managedApplication.subject.user_name}</span>
				</h3>
				<ul class="divide-charcoal/25 divide-y dark:divide-white/25">
					{#each managedApplication.releases as application (application.id)}
						<li
							class="relative flex items-end px-3.5 py-3"
							transition:slide={{ duration: SLIDE_ANIM_MS }}
						>
							{#if confirmManagedApplicationDelete && confirmManagedApplicationDelete?.id === application.id && confirmManagedApplicationDelete?.sub === application.sub}
								<Modal
									position="left"
									css="top-10 md:min-w-[22rem]"
									close={() => (confirmManagedApplicationDelete = null)}
								>
									<div class="flex flex-col items-center justify-between md:flex-row">
										<span>{$_('Confirm Action')}</span>
										<div class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2">
											<button
												onclick={() => (confirmManagedApplicationDelete = null)}
												class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
											>
											<RemoveButton
												onclick={removeManagedApplication}
												isLoading={removeManagedApplicationAjax?.id === application.id &&
													removeManagedApplicationAjax?.sub === application.sub}
												value="Forget"
											/>
										</div>
									</div>
								</Modal>
							{/if}

							<div class="mb-7 w-2 flex-shrink-0">
								{#if editMode}
									<RemoveIconButton
										onclick={() =>
											(confirmManagedApplicationDelete = {
												id: application.id,
												sub: application.sub
											})}
									/>
								{/if}
							</div>

							<div class="inline-flex h-6 w-6 flex-shrink-0 items-center justify-center"></div>

							<div
								class="{$locale && $locale.startsWith('ar')
									? 'mr-2 items-start'
									: 'ml-2'} flex flex-col"
							>
								{#if application.image_uri || application.dark_image_uri}
									<picture>
										<source
											srcset={application.dark_image_uri || application.image_uri}
											media="(prefers-color-scheme: dark)"
										/>
										<img
											src={application.image_uri}
											alt={application.name}
											class="max-h-[1.8rem] max-w-[8rem] object-contain object-left"
										/>
									</picture>
								{/if}

								<span class="mt-1">
									{application.name}
								</span>

								<span class="text-xs italic">
									{$_('First login {date}', {
										values: {
											date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
										}
									})}

									{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country}
										•
										{application.firstRelease?.location?.City
											? `${application.firstRelease?.location?.City},`
											: ''}
										{application.firstRelease?.location?.Country}
									{/if}
								</span>
								<span class="text-xs italic">
									{#if application.lastRelease?.at}
										{$_('Last login {date}', {
											values: {
												date: dayjs(application.lastRelease?.at).locale($locale).fromNow()
											}
										})}
									{:else}
										{$_('Last login {date}', {
											values: {
												date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
											}
										})}
									{/if}

									{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country || application.lastRelease?.location?.City || application.lastRelease?.location?.Country}
										•
										{application.lastRelease?.location.City
											? `${application.lastRelease?.location?.City},`
											: `${application.firstRelease?.location?.City},`}
										{application.lastRelease?.location?.Country ||
											application.firstRelease?.location?.Country}
									{/if}
								</span>
							</div>
						</li>
					{/each}
				</ul>
			{/each}
		</div>
	{/if}

	{#if !global.data.profile.applications?.length && !global.data.profile.managedApplications?.length}
		<span
			class="text-fallback mt-4 block text-sm {$locale && $locale.startsWith('ar')
				? 'mr-6'
				: 'ml-6'}">{$_('No applications')}</span
		>
	{/if}
</section>
