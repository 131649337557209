<script>
	// import { onMount } from 'svelte';
	import tippy from 'sveltejs-tippy';
	import { _, locale } from 'svelte-i18n';
	import { global } from '$src/state.svelte.js';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import VerifyPhone from '$lib/VerifyPhone.svelte';
	import PhoneIcon from '$lib/icon/PhoneIcon.svelte';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import RecoveryProviders from '$lib/login/RecoveryProviders.svelte';
	import logins from '$svr/providers/logins.json';
	// import { promptForPasskey } from '$utils/passkey.js';
	// import { startAuthentication } from '@simplewebauthn/browser';
	// import { getLoginChallenge, postLoginChallenge } from '$lib/passkey.js';

	let {
		heading = '',
		label = '',
		accountSelected = undefined,
		accountToUse = undefined,
		accounts = [],
		showOtherWaysToLogin = true,
		contactLoginSuccess = () => {},
		handleContactError = () => {},
		continueWithEthereumExtension = () => {},
		continueWithProvider = () => {},
		showRecoveries = $bindable(false)
	} = $props();

	const validLoginSlugs = [...logins.map((i) => i.slug), 'email', 'phone', 'ethereum'];

	// let showPasskeyLogin = false;
	// let showPasskeyNotFoundModal = false;

	const loggedInSubject = $derived(
		global.data?.recovery?.find((i) => i.id === global.data?.subjects[0])
	);

	// onMount(async () => {
	// 	showPasskeyLogin = await promptForPasskey();
	// });

	// let passkeyLoginAjax = false;
	// async function passkeyLogin() {
	// 	try {
	// 		passkeyLoginAjax = true;
	// 		const challenge = await getLoginChallenge();
	// 		const signedChallenge = await startAuthentication(challenge);
	// 		const webAuthnLoginRes = await postLoginChallenge(signedChallenge);
	// 		if (!webAuthnLoginRes?.verified) throw webAuthnLoginRes;
	// 		if (window.isWalletAuthorizeApp) {
	// 			global.data = await getConsent();
	// 		} else {
	// 			global.data = await getProfile();
	// 		}
	// 		return replaceRoute('/');
	// 	} catch (err) {
	// 		if (err.status) {
	// 			//no passkeys found
	// 			showPasskeyNotFoundModal = true;
	// 			console.error(err);
	// 		} else {
	// 			notification.show(
	// 				'Authentication cancelled',
	// 				'error'
	// 			);
	// 		}
	// 	} finally {
	// 		passkeyLoginAjax = false;
	// 	}
	// }
</script>

<!-- {#if showPasskeyNotFoundModal}
	<PasskeyNotFoundModal close={() => (showPasskeyNotFoundModal = false)} />
{/if} -->

<section data-test="preferred-providers-container">
	{#if heading}
		<h1 class="relative mb-6 flex items-center justify-center text-lg font-medium">
			{heading}
		</h1>
	{/if}

	{#if label}
		<div class="mb-2 flex items-center justify-between">
			<h2
				class="block text-sm font-medium {$locale && $locale.startsWith('ar')
					? 'text-right'
					: 'text-left'}"
			>
				{@html label}
			</h2>
		</div>
	{/if}
	{#if Array.isArray(accounts)}
		<div class="space-y-2">
			{#each accounts as preferred}
				{#if preferred.slug === 'email'}
					<div class="btn-border hover-none relative h-auto rounded-md px-4">
						<button class="flex h-16 w-full items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'mr-4 text-right'
									: 'ml-4 text-left'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{preferred.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								{accountToUse}
								{accountSelected}
								email={preferred.login_hint}
								login
								disabled
								success={contactLoginSuccess}
								error={handleContactError}
							/>
						</div>
					</div>
				{:else if preferred.slug === 'phone'}
					<div class="btn-border hover-none relative h-auto rounded-md px-4">
						<button class="flex h-16 w-full items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'mr-4 text-right'
									: 'ml-4 text-left'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{preferred.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								{accountToUse}
								{accountSelected}
								phone={preferred.login_hint}
								login
								disabled
								success={contactLoginSuccess}
								error={handleContactError}
							/>
						</div>
					</div>
				{:else if preferred.slug === 'ethereum'}
					{#if window.ethereum}
						<div class="btn-border hover-none relative h-auto space-y-2 rounded-md p-2">
							<span class="mb-3 block text-left"
								>{preferred.wallet?.name || 'Ethereum'}
								{#if preferred.wallet?.display}
									<span use:tippy={{ content: preferred.login_hint, placement: 'top' }}>
										({preferred.wallet?.display})
									</span>
								{/if}
							</span>
							<LoginProvider
								ethereum={() =>
									continueWithEthereumExtension({ info: preferred, accountSelected, accountToUse })}
								provider={preferred}
								hideusername
								prefix="Continue with"
							/>
						</div>
					{/if}
				{:else}
					<LoginProvider
						login={true}
						onclick={() =>
							continueWithProvider({
								slug: preferred.slug,
								body: {
									login_hint: preferred.login_hint,
									accountToUse,
									accountSelected
								},
								server: preferred.slug === 'mastodon' ? preferred.mastodonServer : null,
								preferred: true
							})}
						provider={preferred}
						prefix="Continue with"
					/>
				{/if}
			{/each}
		</div>
	{/if}

	<!-- TBD Figure out passkey login later -->
	<!-- {#if showPasskeyLogin && global.data?.hasPasskey}
		<section class="mt-2">
			<LoginProvider
				onclick={passkeyLogin}
				loading={passkeyLoginAjax}
				disabled={passkeyLoginAjax}
				provider={{ slug: 'passkey' }}
				label="Continue with Hellō Passkey"
			/>
		</section>
	{/if} -->

	{#if showOtherWaysToLogin}
		{@const totalRecoveries =
			global.data.recovery?.filter((i) => validLoginSlugs.includes(i.slug)).length || 0}
		{#if totalRecoveries > 1 && !loggedInSubject && !global.data.isPersonalLoggedIn}
			<div class="my-2 flex items-center justify-between">
				<button
					onclick={() => (showRecoveries = !showRecoveries)}
					class="text-sm hover:underline focus:underline {$locale && $locale.startsWith('ar')
						? 'text-left'
						: 'text-right'}"
				>
					{showRecoveries ? $_('Hide other ways to log in') : $_('Show other ways to log in')}
				</button>
			</div>
		{/if}
	{/if}

	{#if showRecoveries}
		<RecoveryProviders
			{continueWithProvider}
			{continueWithEthereumExtension}
			{contactLoginSuccess}
			{handleContactError}
		/>
	{/if}
</section>
