<script>
	import { _ } from 'svelte-i18n';
	import { fly, slide } from 'svelte/transition';
	import { onMount, onDestroy, tick } from 'svelte';
	import { global } from '$src/state.svelte.js';
	import { validateOtp, preventDefault } from '$utils/common.js';
	import intlTelInput from 'intl-tel-input';
	import 'intl-tel-input/build/css/intlTelInput.css';
	import {
		postLoginPhone,
		postLinkPhone,
		postLoginPhoneCode,
		postLinkPhoneCode,
		postVerifyPhone,
		postVerifyPhoneCode
	} from '$utils/api.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { SLIDE_ANIM_MS } from '$src/constants';
	import SvelteOtp from '@hellocoop/svelte-otp';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';

	let {
		phone = $bindable(''),
		disabled = false,
		accountSelected = undefined,
		useDifferentManaged = undefined,
		login = false,
		accountToUse = undefined,
		verify = false,
		recommendedProvider = false,
		otpState = () => {},
		success = () => {},
		error = () => {}
	} = $props();

	let otp = $state('');
	let buttonDisabled = $state(true);

	let ajaxRequestSend = $state(false);
	let ajaxRequestResend = $state(false);
	let verifyOTPAjax = $state(false);

	let phoneOTPState = $state(false);

	let ref,
		iti = $state();

	let verifiedPhones = $state([]);

	$effect(() => {
		if (validateOtp(otp)) {
			verifyPhoneCode(otp);
		}
	});

	onMount(() => {
		if (!disabled) {
			ref = document.getElementById('tel');
			iti = intlTelInput(ref, {
				autoHideDialCode: false,
				utilsScript: window.intlTelInputUtils,
				separateDialCode: true
			});
			// if (!noautofocus) {
			// 	document.getElementById('tel').focus();
			// }
		}

		if ((global.data?.isPersonalLoggedIn || global.data?.isManagedLoggedIn) && !login && !verify) {
			if (window.isWalletAuthorizeApp && Array.isArray(global.data.release?.phones)) {
				verifiedPhones = global.data.release.phones;
			} else if (
				Array.isArray(global.data.profile?.accounts) &&
				Array.isArray(global.data.profile?.unverified_phones)
			) {
				const _verifiedPhones = global.data.profile.accounts
					.filter(
						(i) =>
							i.slug === 'phone' && !global.data.profile.unverified_phones.includes(i.user_name)
					)
					.map((i) => i.user_name);
				verifiedPhones = _verifiedPhones;
			}
		}
	});

	onDestroy(() => {
		if (iti) {
			iti.destroy();
		}
	});

	async function verifyPhone(phone, resend) {
		let json;
		if (login) json = await postLoginPhone(phone, resend);
		else if (verify) json = await postVerifyPhone(phone, resend);
		else json = await postLinkPhone(phone, resend);

		if (!json.error) {
			verifyPhonePlausible();
			otpState();
			phoneOTPState = true;
			await tick();
			if (!resend) {
				if (!disabled) {
					iti.destroy();
				}
				phoneOTPState = true;
			}
		}
		ajaxRequestSend = ajaxRequestResend = false;
	}

	// TBD move to JS file
	async function verifyPhonePlausible() {
		//New User Release Funnel
		if (window.isWalletAuthorizeApp) {
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
			//session funnel state is valid and not already sent + is authorize app
			if (
				login &&
				indexOfCurrentFunnelStep !== -1 &&
				indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
				window.isWalletAuthorizeApp
			) {
				const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'client_id'
				);
				const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'redirect_uri'
				);
				let redirect;
				try {
					redirect = new URL(redirect_uri)?.hostname;
				} catch (err) {
					console.error(err);
				}
				logPlausibleEvent({
					n: 'AZ Login Start',
					p: {
						client_id,
						provider: 'phone',
						recommended_provider: recommendedProvider,
						redirect
					},
					u: '/login'
				});
				sessionStorage.setItem('az_release_funnel', 'az_login_start');
			}
		}

		//Wizard Funnel
		const isInWizard = !global.data?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		//is trying to link + is in wizard + is wallet app
		if (!login && !verify && isInWizard && window.isWalletApp) {
			const preferred = global.data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				if (sessionStorage.welcome_app_info) {
					try {
						welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
					} catch (err) {
						console.error(err);
					}
				}
			}
			const recovery_1 = global.data?.recovery?.[0]?.slug;
			if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
				await logPlausibleEvent({
					n: 'Wiz Recovery 1 Start',
					p: { preferred, welcome_email_app, recovery_1: 'email' },
					u: '/wizard/recoveryprovider'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start');
			} else if (recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery_1_success') {
				await logPlausibleEvent({
					n: 'Wiz Recovery 2 Start',
					p: { preferred, welcome_email_app, recovery_1, recovery_2: 'email' },
					u: '/wizard/recoveryprovider'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start');
			}
		}
	}

	async function verifyPhoneCode(code) {
		verifyOTPAjax = true;
		let json;
		if (login) {
			json = await postLoginPhoneCode({
				code,
				accountToUse,
				accountSelected,
				useDifferentManaged
			});
		} else if (verify) {
			json = await postVerifyPhoneCode(code);
		} else {
			json = await postLinkPhoneCode(code);
		}
		if (json.error) {
			otp = '';
			verifyOTPAjax = false;
			error(json.eror);
		} else {
			success({ phone: disabled ? phone : iti.getNumber() });
		}
	}
</script>

{#if !phoneOTPState}
	<div id="phone-wrapper" class="w-full text-center">
		<form
			onsubmit={preventDefault(() => {
				ajaxRequestSend = true;
				verifyPhone(disabled ? phone : iti.getNumber());
			})}
		>
			<div>
				{#if !disabled}
					<input
						type="tel"
						name="tel"
						id="tel"
						autocomplete="tel"
						placeholder={$_('enter your phone')}
						class="h-12 w-full bg-transparent px-[16px] sm:px-[18px]"
						required
						oninput={() => {
							buttonDisabled = !iti.isValidNumber();
							phone = iti.getNumber();
						}}
					/>
				{/if}
				{#if !login && disabled}
					<span class="flex h-6 items-center justify-center">{phone}</span>
				{/if}

				{#if verifiedPhones.includes(phone)}
					<span
						data-test="phone-exists-error"
						class="mt-2 block text-left text-red-500"
						transition:slide={{ duration: SLIDE_ANIM_MS }}>Phone has already been verified</span
					>
				{/if}

				<button
					data-test="phone-send-verification-btn"
					type="submit"
					disabled={ajaxRequestSend ||
						(buttonDisabled && !disabled) ||
						verifiedPhones.includes(phone)}
					class="btn-background relative inline-flex h-12 w-full items-center justify-center transition disabled:opacity-60"
					class:mt-3={!disabled || !login}
				>
					{#if ajaxRequestSend}
						<SpinnerIcon css="h-5 w-5 text-white" />
					{:else if login}
						{$_('Send verification code to log in')}
					{:else}
						{$_('Send verification code')}
					{/if}
				</button>
			</div>
		</form>
	</div>
{:else}
	<div class="text-center" in:fly={{ x: 20, duration: SLIDE_ANIM_MS }}>
		<div class="flex items-center justify-center">
			{#if disabled && login}
				<h1 class="text-center text-lg">{$_('Enter the 6-digit code you received')}</h1>
			{:else}
				<h1 class="text-center text-lg">
					{$_('Enter 6 digit code sent to {contact}', {
						values: { contact: disabled ? phone : iti.getNumber() }
					})}
				</h1>
			{/if}
		</div>
		<form>
			{#if !verifyOTPAjax}
				<SvelteOtp
					bind:value={otp}
					autofocus={true}
					numOfInputs={6}
					wrapperClass="!gap-x-2 h-20 flex items-center justify-center"
					numberOnly={true}
					inputClass="text-lg !w-9 !h-11 !border-none"
				/>

				<button
					data-test="phone-resend-verification-btn"
					onclick={preventDefault(() => {
						ajaxRequestResend = true;
						verifyPhone(disabled ? phone : iti.getNumber(), true);
					})}
					disabled={ajaxRequestResend}
					class="relative inline-flex h-5 items-center justify-center text-sm font-medium opacity-80 hover:underline focus:underline"
				>
					{#if ajaxRequestResend}
						<SpinnerIcon css="h-4 w-4 block mx-auto" />
					{:else}
						{$_('Resend verification code')}
					{/if}
				</button>
			{:else}
				<div class="mx-auto flex h-[6.5rem] items-center justify-center">
					<SpinnerIcon css="h-6 w-6 block mx-auto" />
				</div>
			{/if}
		</form>
	</div>
{/if}
