import { locale } from 'svelte-i18n';
import { global } from '$src/state.svelte.js';
import { notification } from '$src/stores.js';
import { NAMES_MAP } from '$src/constants.js';
import {
	checkVersion,
	getDisplay,
	addSourcePropToPictureObj,
	delayedListenForInAppMessages
} from '$utils/common.js';
import { logPlausibleEvent } from '$utils/plausible.js';
import { readTranslatedKey } from '$utils/i18n.js';
import { handleConsentResponse } from '$utils/consent.js';
import { deviceTheme } from '$utils/device.js';
import { send } from '$utils/fetch.js';
import { clearLocalAndSessionStorage } from './storage.js';

//#region GET
export const getProfile = async (query, { showNotification = true } = {}) => {
	const json = await send('GET', '/profile', query);

	checkVersion(json);

	if (json?.profile?.language) {
		//svelte-i18n crashes if initialLocale has commas
		//expected type is string and not array
		//stringify and check even if array is passed
		const language = json.profile.language.toString()?.split(',')?.[0];
		if (language) {
			localStorage.setItem('lang', language);
			await locale.set(language);
		}
	}

	if (json?.profile?.pictures) {
		json.profile.pictures = addSourcePropToPictureObj(json.profile.pictures);
	}

	if (json?.upgrade) {
		showNotification = false;
		global.upgradePreferred = json?.upgrade;

		//Start of Wiz Upgrade Funnel
		//wizard upgrade funnel state is not already sent + is logged in + is in wizard
		const isInWizard = !json?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		if (!sessionStorage.wiz_upgrade_funnel && json.isPersonalLoggedIn && isInWizard) {
			const contact_slug = json?.upgrade?.preferred?.slug;
			const email_domain = json?.upgrade?.preferred?.user_name?.split('@')?.[1];
			const upgrade_slug = json?.upgrade?.upgrade?.slug;
			sessionStorage.setItem('wiz_upgrade_funnel', 'wiz_upgrade_prompt');
			logPlausibleEvent({
				n: 'Wiz Upgrade Prompt',
				p: { contact_slug, email_domain, upgrade_slug },
				u: '/'
			});
		}
	}

	if (json?.merge) {
		showNotification = false;
		global.mergeAccounts = json?.merge;
	}

	if (showNotification && json.notification) {
		if (json.notification?.type === 'link') {
			logPlausibleEvent({ u: `/link/${json.notification?.slug}`, n: 'action' });
		} else if (json.notification?.type === 'authority') {
			const id =
				json.notification.preferred || json.notification.recovery || json.notification.no_recovery;
			const slug = json.profile?.accounts?.find((i) => i.id === id).slug;
			logPlausibleEvent({ u: `/verify/${slug}`, n: 'action' });
		}

		if (json.notification?.type !== 'merge') {
			if (json.notification?.attribute && json.notification?.slug) {
				const claim =
					json.notification.attribute.charAt(0).toUpperCase() +
					json.notification.attribute.substring(1);
				notification.show(
					readTranslatedKey(`${claim} from {provider} has been added`, {
						values: {
							provider: getDisplay(json.notification?.slug)
						}
					}),
					'success'
				);
			} else if (json.notification?.slug) {
				notification.show(
					readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(json.notification?.slug)
						}
					}),
					'success'
				);
			}
		}

		if (json.notification.error === 'MANAGED_ACCOUNT_LOGGED_IN' && json.notification.domain) {
			notification.show(
				readTranslatedKey(
					'{domain} is managed by an organization and cannot be your preferred personal provider',
					{
						values: {
							domain: json.notification.domain
						}
					}
				),
				'error'
			);
		}
	}

	if (sessionStorage.showWalletMergedNotification) {
		//show notification that was set in sessionstorage before page reload - page was reloaded to get latest state
		notification.show(readTranslatedKey('Wallets merged successfully'), 'success');
		sessionStorage.removeItem('showWalletMergedNotification');
	}

	return json;
};

export const getConsent = async (query, { showNotification = true } = {}) => {
	const json = await send('GET', '/consent', query);

	checkVersion(json);

	if (json?.language) {
		//svelte-i18n crashes if initialLocale has commas
		//expected type is string and not array
		//stringify and check even if array is passed
		const language = json.language.toString()?.split(',')?.[0];
		if (language) {
			localStorage.setItem('lang', language);
			await locale.set(language);
		}
	}

	if (json?.release?.pictures) {
		json.release.pictures = addSourcePropToPictureObj(json.release.pictures);
	}

	if (json?.upgrade) {
		showNotification = false;
		global.upgradePreferred = json?.upgrade;
	}

	if (json?.merge) {
		showNotification = false;
		global.mergeAccounts = json?.merge;
	}

	if (json?.remoteDevice) {
		global.isRemoteAuthClient = true;
	}

	if (showNotification && json.notification) {
		if (
			json.notification &&
			!['verified-name', 'existing-name', 'existing-username'].includes(
				json.notification?.attribute
			)
		) {
			if (json.notification?.attribute) {
				notification.show(
					`${
						json.notification.attribute.charAt(0).toUpperCase() +
						json.notification.attribute.substring(1)
					} from ${getDisplay(json.notification?.slug)} has been added`,
					'success'
				);
			} else if (json.notification.error === 'PERSONAL_REQUESTED') {
				notification.show(
					readTranslatedKey(
						`A personal account was requested. {provider} ({email}) is a managed account`,
						{
							values: {
								provider: getDisplay(json.notification?.slug),
								email: json.notification.lastLogin
							}
						}
					),
					'error'
				);
			} else if (json.notification.error === 'MANAGED_REQUESTED') {
				notification.show(
					readTranslatedKey(
						`A managed account was requested. {provider} ({email}) is a personal account`,
						{
							values: {
								provider: getDisplay(json.notification?.slug),
								email: json.notification.lastLogin
							}
						}
					),
					'error'
				);
			} else {
				notification.show(
					readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(json.notification?.slug)
						}
					}),
					'success'
				);
			}
		}
	}

	if (json.app) {
		sessionStorage.setItem('app', JSON.stringify(json.app));
	}

	if (sessionStorage.showWalletMergedNotification) {
		//show notification that was set in sessionstorage before page reload - page was reloaded to get latest state
		notification.show(readTranslatedKey('Wallets merged successfully'), 'success');
		sessionStorage.removeItem('showWalletMergedNotification');
	}

	return json;
};

export const getInvite = async (query, { showNotification = true } = {}) => {
	const json = await send('GET', '/invite', query);

	checkVersion(json);

	if (json?.upgrade) {
		showNotification = false;
		global.upgradePreferred = json?.upgrade;
	}

	if (json?.merge) {
		showNotification = false;
		global.mergeAccounts = json?.merge;
	}

	if (showNotification && json.notification) {
		if (json.notification?.type === 'link') {
			logPlausibleEvent({ u: `/link/${json.notification?.slug}`, n: 'action' });
		} else if (json.notification?.type === 'authority') {
			const id = json.notification.preferred || json.notification.recovery;
			const slug = json.profile?.accounts?.find((i) => i.id === id).slug;
			logPlausibleEvent({ u: `/verify/${slug}`, n: 'action' });
		}

		if (json.notification?.type !== 'merge') {
			if (json.notification?.attribute && json.notification?.slug) {
				const claim =
					json.notification.attribute.charAt(0).toUpperCase() +
					json.notification.attribute.substring(1);
				notification.show(
					readTranslatedKey(`${claim} from {provider} has been added`, {
						values: {
							provider: getDisplay(json.notification?.slug)
						}
					}),
					'success'
				);
			} else if (json.notification?.slug) {
				notification.show(
					readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(json.notification?.slug)
						}
					}),
					'success'
				);
			}
		}
	}

	if (sessionStorage.showWalletMergedNotification) {
		//show notification that was set in sessionstorage before page reload - page was reloaded to get latest state
		notification.show(readTranslatedKey('Wallets merged successfully'), 'success');
		sessionStorage.removeItem('showWalletMergedNotification');
	}

	return json;
};

export const keepAlive = async () => {
	return send('GET', '/login/keep_alive');
};

const getLogos = async (path) => {
	const json = await send('GET', path);

	// calculate width and height
	const promises = [];
	for (const logo of json) {
		const img = new Image();
		img.src = logo.url;
		const promise = new Promise((resolve) => {
			img.onload = () => {
				logo.width = img.naturalWidth;
				logo.height = img.naturalWidth;
				resolve(logo);
			};
			img.onerror = () => {
				console.warn('Failed to fetch logo at: ' + logo.url);
				resolve();
			};
		});
		promises.push(promise);
	}
	const logos = await Promise.all(promises);

	return logos.filter(Boolean); //filter out unresolved images
};

export const getManagedLogos = async (managedID) => {
	return getLogos('/managed/' + managedID + '/logos');
};

export const getM2Logos = async (managedID) => {
	return getLogos('/m2/organization/' + managedID + '/logos');
};

export const getCreateChallenge = async () => {
	// TBD property error checking
	return send('GET', '/passkey/create');
};

export const getLoginChallenge = async () => {
	// TBD property error checking
	return send('GET', '/passkey/login');
};

export const getMastodonDiscovery = async (server) => {
	// TBD move query to component layer
	const query = new URLSearchParams();
	query.set('server', server);

	return send('GET', '/nodeinfo/2.0', query);
};

export const getManagedDicsovery = async (email) => {
	// TBD move query to component layer
	const query = new URLSearchParams();
	query.set('email', email);

	return send('GET', '/managed/provider', query);
};

export const getEmailProviderDiscovery = async (domain) => {
	return send('GET', '/email/provider/' + domain);
};

export const getM2Org = async (query) => {
	// TBD move query to component layer
	// TBD property error checking
	return send('GET', '/m2/organization?' + query);
};

export const getM2OrgUsers = async (managedID) => {
	// TBD move query to component layer
	// TBD property error checking
	return send('GET', '/m2/organization/' + managedID + '/users');
};

export const getInvitation = async (invitationID) => {
	return send('GET', '/invitation/' + invitationID);
};

//#endregion

//#region POST
export const postLoginProvider = async ({ slug = '', server = '', body = {} } = {}) => {
	// TBD move query to component layer
	const query = new URLSearchParams();

	if (window.isWalletApp) {
		query.set('redirect_path', '/');
	} else if (window.isWalletAuthorizeApp) {
		query.set('redirect_path', '/authorize');
	} else if (window.isWalletInviteApp) {
		query.set('redirect_path', '/invite');
	}

	if (server) {
		query.set('server', server);
	}

	const json = await send('POST', '/login/redirect/' + slug, query, body);

	if (!json.redirect) throw new Error('redirect missing');

	await logPlausibleEvent({ u: '/start/login/' + slug, n: 'action' });

	// kick off timer to detect we are in an IAB
	delayedListenForInAppMessages();

	return json;
};

export const postLinkProvider = async ({
	slug = '',
	attribute = '',
	server = '',
	body = {}
} = {}) => {
	// TBD move query to component layer
	const query = new URLSearchParams();

	if (window.isWalletApp) {
		query.set('redirect_path', '/');
	} else if (window.isWalletAuthorizeApp) {
		query.set('redirect_path', '/authorize');
	} else if (window.isWalletInviteApp) {
		query.set('redirect_path', '/invite');
	}

	if (server) {
		query.set('server', server);
	}

	const json = await send(
		'POST',
		'/link/redirect/' + slug + (attribute ? '/' + attribute : ''),
		query,
		body
	);

	if (!json.error) {
		if (!json.redirect) throw new Error('redirect missing');
	}

	await logPlausibleEvent({ u: '/start/link/' + slug, n: 'action' });

	// kick off timer to detect we are in an IAB
	delayedListenForInAppMessages();

	return json;
};

export const postLoginEmail = async (email, resend) => {
	const json = await send('POST', '/login/contact/email', null, { email });

	if (!json.email) throw new Error('email missing');

	if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/login/email', n: 'action' });
	}

	return json;
};

export const postLoginEmailCode = async (body) => {
	const query = new URLSearchParams();
	query.set('prefers-color-scheme', deviceTheme);
	query.set('language', window.navigator.language);
	const json = await send('POST', '/login/contact/email/code', query, body);

	if (!json.error) {
		if (!json.email) throw new Error('email missing');
	}

	logPlausibleEvent({ u: '/login/email', n: 'action' });
	notification.clear();

	return json;
};

export const postLoginPhone = async (phone, resend) => {
	const json = await send('POST', '/login/contact/phone', null, { phone });

	if (!json.phone) throw new Error('phone missing');

	if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/login/phone', n: 'action' });
	}

	return json;
};

export const postLoginPhoneCode = async (body) => {
	const query = new URLSearchParams();
	query.set('prefers-color-scheme', deviceTheme);
	query.set('language', window.navigator.language);
	const json = await send('POST', '/login/contact/phone/code', query, body);

	if (!json.error) {
		if (!json.phone) throw new Error('phone missing');
	}

	logPlausibleEvent({ u: '/login/phone', n: 'action' });
	notification.clear();

	return json;
};

export const postVerifyEmail = async (email, resend) => {
	const json = await send('POST', '/verify/contact/email', null, { email });

	if (!json.error) {
		if (!json.email) throw new Error('email missing');
	}

	if (json.error) {
		if (json.error?.message === 'EMAIL_ALREADY_VERIFIED') {
			notification.show(
				readTranslatedKey('{contact} has already been verified', {
					values: { contact: email }
				}),
				'error'
			);
		}
	} else if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/verify/email', n: 'action' });
	}

	return json;
};

export const postVerifyEmailCode = async (code) => {
	const json = await send('POST', '/verify/contact/email/code', null, { code });

	if (!json.error) {
		if (!json.email) throw new Error('email missing');
	}

	if (json.error?.message === 'INVALID_CODE') {
		notification.show(readTranslatedKey('Incorrect verification code'), 'error');
	} else if (!json.merge) {
		notification.show(readTranslatedKey('Phone has been verified'), 'success');
	}

	if (!json.error) logPlausibleEvent({ u: '/verify/email', n: 'action' });

	if (json?.merge) {
		global.mergeAccounts = json.merge;
	}

	return json;
};

export const postVerifyPhone = async (phone, resend) => {
	const json = await send('POST', '/verify/contact/phone', null, { phone });

	if (!json.error) {
		if (!json.phone) throw new Error('phone missing');
	}

	if (json.error) {
		if (json.error?.message === 'PHONE_ALREADY_VERIFIED') {
			notification.show(
				readTranslatedKey('{contact} has already been verified', {
					values: { contact: phone }
				}),
				'error'
			);
		}
	} else if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/verify/phone', n: 'action' });
	}

	return json;
};

export const postVerifyPhoneCode = async (code) => {
	const json = await send('POST', '/verify/contact/phone/code', null, { code });

	if (!json.error) {
		if (!json.phone) throw new Error('phone missing');
	}

	if (json.error?.message === 'INVALID_CODE') {
		notification.show(readTranslatedKey('Incorrect verification code'), 'error');
	} else if (!json.merge) {
		notification.show(readTranslatedKey('Phone has been verified'), 'success');
	}

	if (!json.error) logPlausibleEvent({ u: '/verify/phone', n: 'action' });

	if (json?.merge) {
		global.mergeAccounts = json.merge;
	}

	return json;
};

export const postLinkEmail = async (email, resend) => {
	const json = await send('POST', '/link/contact/email', null, { email });

	if (!json.error) {
		if (!json.email) throw new Error('email missing');
	}

	if (json.error) {
		if (json.error?.message === 'EMAIL_ALREADY_VERIFIED') {
			notification.show(
				readTranslatedKey('{contact} has already been verified', {
					values: { contact: email }
				}),
				'error'
			);
		}
	} else if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/link/email', n: 'action' });
	}

	return json;
};

export const postLinkEmailCode = async (code, showNotification = true) => {
	const json = await send('POST', '/link/contact/email/code', null, { code });

	if (!json.error) {
		if (!json.email) throw new Error('email missing');
	}

	if (json.error?.message === 'INVALID_CODE') {
		notification.show(readTranslatedKey('Incorrect verification code'), 'error');
	} else if (showNotification) {
		if (!json.merge) {
			notification.show(readTranslatedKey('Email has been added'), 'success');
		}
	}

	if (!json.error) logPlausibleEvent({ u: '/link/email', n: 'action' });

	return json;
};

export const postLinkPhone = async (phone, resend) => {
	const json = await send('POST', '/link/contact/phone', null, { phone });

	if (!json.error) {
		if (!json.phone) throw new Error('phone missing');
	}

	if (json.error) {
		if (json.error?.message === 'PHONE_ALREADY_VERIFIED') {
			notification.show(
				readTranslatedKey('{contact} has already been verified', {
					values: { contact: phone }
				}),
				'error'
			);
		}
	} else if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/link/phone', n: 'action' });
	}
	return json;
};

export const postLinkPhoneCode = async (code, showNotification = true) => {
	const json = await send('POST', '/link/contact/phone/code', null, { code });

	if (!json.error) {
		if (!json.phone) throw new Error('phone missing');
	}

	if (json.error?.message === 'INVALID_CODE') {
		notification.show(readTranslatedKey('Incorrect verification code'), 'error');
	} else if (showNotification) {
		if (!json.merge) {
			notification.show(readTranslatedKey('Phone has been added'), 'success');
		}
	}

	if (!json.error) logPlausibleEvent({ u: '/link/phone', n: 'action' });

	return json;
};

export const postWizard = async () => {
	const json = await send('POST', '/profile/wizard');

	if (!json.error) {
		if (!json.actions) throw new Error('actions missing');
	}

	return json;
};

export const postLoginEth = async (address) => {
	const params = {
		address,
		uri: window.location.origin + window.location.pathname
	};
	const json = await send('POST', '/login/ethereum/challenge', null, params);

	if (!json.challenge) throw new Error('challenge missing');

	return json;
};

export const postLoginEthChallenge = async (params) => {
	// TBD move query to component layer
	const query = new URLSearchParams();
	query.set('prefers-color-scheme', deviceTheme);
	query.set('language', window.navigator.language);

	const json = await send('POST', '/login/ethereum', query, params);

	if (!json.success) throw new Error('failed to login ethereum with address: ' + params.address);

	return json;
};

export const postLinkEth = async (address) => {
	const params = {
		address,
		uri: window.location.origin + window.location.pathname
	};
	const json = await send('POST', '/link/ethereum/challenge', null, params);

	if (!json.challenge && !json.error) throw new Error('missing required property');

	return json;
};

export const postLinkEthChallenge = async (params) => {
	const json = await send('POST', '/link/ethereum', null, params);

	if (!json.error) {
		if (!json.success) throw new Error('success missing');
	}

	return json;
};

export const postConsent = async (params) => {
	return await send('POST', '/consent', null, params);
};

export const postPicture = async (picture) => {
	const json = await send('POST', '/profile/picture', null, picture);

	if (!json.error) {
		if (!json.pictures) throw new Error('pictures missing');
	}

	notification.show(readTranslatedKey('Profile picture added'), 'success');

	return json;
};

const postLogo = async (path, logo) => {
	const json = await send('POST', path, null, logo);

	if (!json.error) {
		if (!json.url) throw new Error('url missing');
	}

	// calculate width and height
	const img = new Image();
	img.src = json.url;
	const promise = new Promise((resolve) => {
		img.onload = () => {
			json.width = img.naturalWidth;
			json.height = img.naturalWidth;
			resolve(json);
		};
		img.onerror = () => {
			resolve(json);
		};
	});

	return promise;
};

export const postManagedLogo = async (managedID, logo) => {
	return postLogo('/managed/' + managedID + '/logo', logo);
};

export const postM2Logo = async (managedID, logo) => {
	return postLogo('/m2/organization/' + managedID + '/logo', logo);
};

export const postCreateChallenge = async (params) => {
	// TBD property error checking
	return send('DELETE', '/passkey/create', null, params);
};

export const postLoginChallenge = async (params) => {
	// TBD property error checking
	return send('DELETE', '/passkey/login', null, params);
};

export const postNoPromptPasskey = async () => {
	// TBD property error checking
	return send('DELETE', '/passkey/no_prompt');
};

export const postInvite = async (params = { email: null, local: false }) => {
	const json = await send('POST', '/invite', null, params);

	if (!json.error) {
		if (!json.invite) throw new Error('invite missing');
	}

	return json;
};

export const postUserCode = async (user_code) => {
	const json = await send('POST', '/user/code', null, { user_code });

	if (!json.error) {
		if (!json.url) throw new Error('url missing');
	}

	return json;
};

export const postReportAbuse = async (invitationID) => {
	return send('POST', '/invitation/' + invitationID + '/report');
};

//#endregion

//#region PUT
export const changeLanguage = async (lang) => {
	const json = await send('PUT', '/profile/language/' + lang);

	if (!json.error) {
		if (!json.language) throw new Error('language missing');
	}

	return json;
};

export const putMerge = async () => {
	return send('PUT', '/merge');
};

export const putName = async (type, name, showNotification = true) => {
	const json = await send('PUT', '/profile/' + type, null, { [type]: name });

	if (!json.error) {
		const key = type + 's';
		if (!json[key]) throw new Error(key + ' missing');
	}

	if (json.error?.message === 'DUPLICATE_VALUE') {
		const txt = readTranslatedKey('Entry already exists');
		notification.show(readTranslatedKey(txt), 'error');
	} else if (showNotification) {
		if (!json.error) {
			const txt = readTranslatedKey(NAMES_MAP[type] + ' added');
			notification.show(readTranslatedKey(txt), 'success');
		}
	}

	return json;
};

export const putPreferred = async (params, { redirectPathParam = false, server = null } = {}) => {
	// TBD move query to component layer
	const query = new URLSearchParams();

	// TBD rework this pass which app we are from component
	if (redirectPathParam) {
		if (window.isWalletApp) {
			query.set('redirect_path', '/');
		} else if (window.isWalletAuthorizeApp) {
			query.set('redirect_path', '/authorize');
		} else if (window.isWalletInviteApp) {
			query.set('redirect_path', '/invite');
		}
	}

	if (server) query.set('server', server);

	const json = await send('PUT', '/profile/authority', query, params);

	if (!json.error) {
		if (!json.accounts && !json.verify) throw new Error('missing required property');
	}

	return json;
};

export const putManaged = async (managedID, params) => {
	const json = await send('PUT', '/managed/' + managedID + '/who', null, params);

	if (!json.success) throw new Error('failed to put managed');

	return json;
};

export const putManagedLogo = async (managedID, params) => {
	const json = await send('PUT', '/managed/' + managedID + '/logo/select', null, params);

	if (!json.success) throw new Error('failed to put managed');

	return json;
};

export const putM2OrgDomain = async (managedID, domain) => {
	// TBD move query to component layer
	// TBD property error checking
	return send('PUT', '/m2/organization/' + managedID + '/domain', null, { domain });
};

export const putM2OrgLogo = async (managedID, params) => {
	// TBD move query to component layer
	// TBD property error checking
	return send('PUT', '/m2/organization/' + managedID + '/logo', null, params);
};

export const putM2Managed = async (managedID, params) => {
	// TBD move query to component layer
	// TBD property error checking
	return send('PUT', '/m2/organization/' + managedID + '/who', null, params);
};

export const putInvite = async (inviteID) => {
	const json = await send('PUT', '/invite/' + inviteID);

	if (!json.success) throw new Error('failed to reinvite with id: ', inviteID);

	return json;
};

export const acceptInvitation = async (invitationID) => {
	return send('PUT', '/invitation/' + invitationID);
};
//#endregion

//#region DELETE
export const deleteName = async (type, ordinal) => {
	const query = new URLSearchParams();
	query.set('ordinal', ordinal);
	const json = await send('DELETE', '/profile/' + type, query);

	if (!json.error) {
		const key = type + 's';
		if (!json[key]) throw new Error(key + ' missing');
	}

	const txt = readTranslatedKey(NAMES_MAP[type] + ' removed');
	notification.show(txt, 'success');

	return json;
};

export const deletePicture = async (ordinal) => {
	// TBD move query to component layer
	const query = new URLSearchParams();
	query.set('ordinal', ordinal);

	const json = await send('DELETE', '/profile/picture', query);

	if (!json.error) {
		if (!json.pictures) throw new Error('pictures missing');
	}

	notification.show(readTranslatedKey('Profile picture removed'), 'success');

	return json;
};

export const deleteDevice = async (id) => {
	const json = await send('DELETE', '/profile/delete/' + id);

	if (!json.success) throw new Error('failed to delete device with id: ' + id);

	notification.show(readTranslatedKey('Device removed'), 'success');

	return json;
};

export const deleteApplication = async (id) => {
	const json = await send('DELETE', '/profile/application/' + id);

	if (!json.success) throw new Error('failed to delete app with id: ' + id);

	notification.show(readTranslatedKey('Application removed'), 'success');

	return json;
};

export const deleteManagedApplication = async ({ id, sub } = {}) => {
	// TBD move query to component layer
	const query = new URLSearchParams();
	query.set('sub', sub);

	const json = await send('DELETE', '/profile/application/' + id + '/managed', query);

	if (!json.success) throw new Error('failed to delete managed app with id: ' + id);

	notification.show(readTranslatedKey('Application removed'), 'success');

	return json;
};

export const deleteProvider = async (id) => {
	const json = await send('DELETE', '/profile/account', null, { id });

	if (!json.error) {
		if (!json.accounts) throw new Error('accounts missing');
	}

	notification.show(readTranslatedKey('Provider removed'), 'success');

	return json;
};

export const deleteUnverifiedProvider = async (ordinal, type) => {
	const query = new URLSearchParams();
	query.set('ordinal', ordinal);
	const json = await send('DELETE', '/profile/unverified_' + type, ordinal);

	if (!json.error) {
		const key = type + 's';
		if (!json[key]) throw new Error(key + ' missing');
	}

	if (type === 'email') {
		notification.show(readTranslatedKey('Unverified email removed'), 'success');
	} else if (type === 'phone') {
		notification.show(readTranslatedKey('Unverified phone removed'), 'success');
	} else {
		notification.show(readTranslatedKey('Unverified account removed'), 'success');
	}

	return json;
};

export const deleteCookies = async () => {
	return send('DELETE', '/cookies');
};

export const deleteProfile = async () => {
	return send('DELETE', '/profile');
};

export const deleteConsent = async () => {
	global.spinner = true;
	const json = await send('DELETE', '/consent');
	return handleConsentResponse(json);
};

export const deleteConsentOnly = async () => {
	return await send('DELETE', '/consent');
};

export const deleteLogin = async ({ clearSession = true } = {}) => {
	// TBD move to component layer
	if (clearSession) {
		clearLocalAndSessionStorage();
	}

	const json = await send('DELETE', '/login');

	logPlausibleEvent({ u: '/logout', n: 'action' });

	return json;
};

export const deleteInvite = async (inviteID) => {
	const json = await send('DELETE', '/invite/' + inviteID);

	if (!json.success) throw new Error('failed to delete invite with id: ', inviteID);

	return json;
};

export const deleteInvitation = async (invitationID) => {
	return send('DELETE', '/invitation/' + invitationID);
};

export const deleteUserCode = async (user_code) => {
	// TBD move query to component layer
	const query = new URLSearchParams();
	query.set('user_code', user_code);

	return send('DELETE', '/user/code', query);
};

export const deleteLoginQRCode = async () => {
	return send('DELETE', '/login/qrcode');
};

export const deleteMerge = async () => {
	return send('DELETE', '/merge');
};
//#endregion
