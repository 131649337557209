<script>
	import { notification } from '$src/stores.js';
	import { _, locale, locales } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { changeLanguage } from '$utils/api.js';
	import { SLIDE_ANIM_MS } from '$src/constants.js';
	import Dropdown from '$lib/Dropdown.svelte';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';

	let { expanded = $bindable(false), editMode = false, handleDropdown = () => {} } = $props();

	let changePreferredLanguageAjax = $state(null);
	async function changePreferredLanguage(langShortCode) {
		changePreferredLanguageAjax = langShortCode;
		await changeLanguage(langShortCode);
		localStorage.setItem('lang', langShortCode);
		await locale.set(langShortCode);
		expanded = false;
		notification.show(
			$_('Preferred language is now {language}', {
				values: {
					language: $_(langShortCode)
				}
			}),
			'success'
		);
		changePreferredLanguageAjax = null;
	}
</script>

<section id="preferred-language" class="py-2 {!editMode ? 'mt-10' : 'mt-4'}">
	<h2 class="text-lg font-medium">{$_('Preferred Language')}</h2>
	<div
		class="relative mt-2 flex items-center {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}"
	>
		<Dropdown
			hasBackground={false}
			dataTest="change-preferred-language-btn"
			ariaLabel={$locales.includes($locale)
				? $_($locale)
				: $locale.includes('-') && $locales.includes($locale.split('-')[0])
					? $_($locale.split('-')[0])
					: $_('en')}
			{expanded}
			onclick={() => handleDropdown('language')}
		>
			<div class="flex h-12 items-center px-4 text-left" aria-hidden="true">
				{#if $locales.includes($locale)}
					{$_($locale)}
				{:else if $locale.includes('-') && $locales.includes($locale.split('-')[0])}
					{$_($locale.split('-')[0])}
				{:else}
					{$_('en')}
				{/if}
			</div>

			{#if expanded}
				<div
					class="z-30 flex w-full flex-col space-y-2 rounded-md p-4 text-left"
					transition:slide={{ duration: SLIDE_ANIM_MS }}
				>
					{#each [...$locales.sort()].filter((i) => i !== $locale && $locale !== i) as l}
						<button
							data-test={`${l}-language-btn`}
							onclick={() => changePreferredLanguage(l)}
							class="px-3 {$locale && $locale.startsWith('ar')
								? 'text-right'
								: 'text-left'} btn-background flex-shrink-0 {changePreferredLanguageAjax === l
								? 'flex items-center justify-center'
								: ''}"
							disabled={changePreferredLanguageAjax === l}
						>
							{#if changePreferredLanguageAjax === l}
								<SpinnerIcon css="h-5 w-5 mx-auto" />
							{:else}
								{$_(l)}
							{/if}
						</button>
					{/each}
				</div>
			{/if}
		</Dropdown>
	</div>
</section>
