<script>
	import * as sort from '$utils/sort';
	import dayjs from '$utils/day.js';
	import { _, locale } from 'svelte-i18n';
	import { global } from '$src/state.svelte.js';
	import { dedupe } from '$utils/common';
	import { updateAccounts } from '$utils/profile';
	import { getProfile, deleteDevice } from '$utils/api.js';
	import { slide } from 'svelte/transition';
	import { SLIDE_ANIM_MS } from '$src/constants';
	import { getDeviceInfo } from '$utils/device.js';
	import RemoveIconButton from '$lib/button/RemoveIconButton.svelte';
	import Modal from '$lib/modal/Modal.svelte';
	import MobileIcon from '$lib/icon/MobileIcon.svelte';
	import TabletIcon from '$lib/icon/TabletIcon.svelte';
	import DesktopIcon from '$lib/icon/DesktopIcon.svelte';
	import AppleIcon from '$lib/icon/AppleIcon.svelte';
	import AndroidIcon from '$lib/icon/AndroidIcon.svelte';
	import WindowsIcon from '$lib/icon/WindowsIcon.svelte';
	import LinuxIcon from '$lib/icon/LinuxIcon.svelte';
	import RemoveButton from '$lib/button/RemoveButton.svelte';

	let { editMode = false } = $props();

	let confirmDeviceDelete = $state();

	let removeDeviceAjax = $state(false);
	async function removeDevice() {
		removeDeviceAjax = confirmDeviceDelete;
		await deleteDevice(confirmDeviceDelete);
		global.data = await getProfile();
		global.data = updateAccounts(global.data); //add sortrank, sortlabel and unverified accounts to accounts array
		confirmDeviceDelete = null;
		removeDeviceAjax = null;
	}
</script>

<section id="devices" class="mt-10">
	<div class="flex items-center justify-between">
		<h2 class="text-lg font-medium">{$_('Devices')}</h2>
		<!-- <div class="inline-flex items-center">
            <span class="text-sm">No Login Prompt</span>
            <HelpButton
                ariaLabel="Help"
                content={$_('Remembers your login choice on each device')}
                placement="top"
            />
        </div> -->
	</div>

	{#if global.data.profile.devices?.length}
		{@const devices = global.data.profile?.devices?.filter(
			(i) =>
				i.id === global.data.profile?.currentDevice ||
				(i.createdAt?.at && i.lastAccess?.at && i.createdAt?.at !== i.lastAccess?.at)
		)}
		<ul class="mt-2 space-y-4">
			{#each dedupe(devices, 'id').sort( (a, b) => sort.devices(global.data.profile.currentDevice, a, b) ) as device (device.id)}
				{@const deviceInfo = getDeviceInfo(device?.created?.userAgent)}
				<li class="relative flex items-start" transition:slide={{ duration: SLIDE_ANIM_MS }}>
					{#if confirmDeviceDelete && confirmDeviceDelete === device.id}
						<Modal
							position="left"
							css="top-10 md:min-w-[22rem]"
							close={() => (confirmDeviceDelete = null)}
						>
							<div class="flex flex-col items-center justify-between md:flex-row">
								<span>{$_('Confirm Action')}</span>
								<div class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2">
									<button
										onclick={() => (confirmDeviceDelete = null)}
										class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
									>
									<RemoveButton
										onclick={removeDevice}
										isLoading={removeDeviceAjax === device.id}
										value="Forget"
									/>
								</div>
							</div>
						</Modal>
					{/if}

					<div class="mt-1 h-6 w-6 flex-shrink-0">
						{#if editMode && global.data.profile.currentDevice !== device.id}
							<RemoveIconButton onclick={() => (confirmDeviceDelete = device.id)} />
						{/if}
					</div>

					<span class="relative -mt-1 inline-flex h-12 w-12 items-center justify-center">
						{#if deviceInfo.isApple}
							<span class="absolute">
								<AppleIcon />
							</span>
						{:else if deviceInfo.isAndroid}
							<span class="absolute">
								<AndroidIcon />
							</span>
						{:else if deviceInfo.isLinux}
							<span class="absolute">
								<LinuxIcon />
							</span>
						{:else if deviceInfo.isWindows}
							<span class="absolute" class:mb-2={deviceInfo.isTypeUnknown}>
								<WindowsIcon />
							</span>
						{/if}

						{#if deviceInfo.isTypeMobile}
							<MobileIcon />
						{:else if deviceInfo.isTypeTablet}
							<TabletIcon />
						{:else}
							<DesktopIcon />
						{/if}
					</span>

					<div class="{$locale && $locale.startsWith('ar') ? 'mr-2' : 'ml-2'} flex flex-col">
						<span>{deviceInfo.browser} {deviceInfo.os}</span>
						<span class="text-xs italic">
							{$_('First login {date}', {
								values: {
									date: dayjs(device.createdAt).locale($locale).fromNow()
								}
							})}

							{#if device.created?.location?.City || device.created?.location?.Country}
								•
								{device.created?.location?.City ? `${device.created?.location?.City},` : ''}
								{device.created?.location?.Country}
							{/if}
						</span>
						<span
							class="text-xs italic"
							class:font-semibold={global.data.profile.currentDevice === device.id}
						>
							{#if global.data.profile.currentDevice === device.id}
								{$_('Current device')}
							{:else if device.lastAccess}
								{$_('Last login {date}', {
									values: {
										date: dayjs(device.lastAccess?.at).locale($locale).fromNow()
									}
								})}
							{:else}
								{$_('Last login {date}', {
									values: {
										date: dayjs(device.createdAt).locale($locale).fromNow()
									}
								})}
							{/if}

							{#if device.lastAccess?.location.City || device.created?.location?.City || device.lastAccess?.location?.Country || device.created?.location?.Country}
								•
								{device.lastAccess?.location.City
									? `${device.lastAccess?.location.City},`
									: `${device.created?.location.City},`}
								{device.lastAccess?.location?.Country || device.created?.location?.Country}
							{/if}
						</span>
					</div>

					<!-- <input
                        onclick={(e) => togglePrompt(e.target.checked, device.id)}
                        type="checkbox"
                        name="no_prompt"
                        checked={device.noPrompt}
                        class="form-checkbox"
                    /> -->
				</li>
			{/each}
		</ul>
	{:else}
		<span class="text-fallback mt-2 text-sm">{$_('No devices')}</span>
	{/if}
</section>
