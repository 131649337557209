<script>
	import { _ } from 'svelte-i18n';
	import { fly, slide } from 'svelte/transition';
	import { global } from '$src/state.svelte.js';
	import { onMount } from 'svelte';
	import SvelteOtp from '@hellocoop/svelte-otp';
	import {
		postLoginEmail,
		postLinkEmail,
		postVerifyEmail,
		postVerifyEmailCode,
		postLoginEmailCode,
		postLinkEmailCode,
		getEmailProviderDiscovery,
		postLoginProvider,
		postLinkProvider
	} from '$utils/api.js';
	import { getDisplay, validateOtp, preventDefault } from '$utils/common.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { isValidEmail } from '$utils/email.js';
	import { SLIDE_ANIM_MS } from '$src/constants.js';
	import { emailDomains } from '$utils/email.js';
	import logins from '$svr/providers/logins.json';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import LoginProvider from '$lib/LoginProvider.svelte';

	let showProviderPrompt = $state(false); //also hold slug

	let otp = $state('');
	let ajaxRequestSend = $state(false);
	let ajaxRequestResend = $state(false);

	let {
		disabled = false,
		email = $bindable(''),
		login = false,
		accountToUse = undefined,
		accountSelected = undefined,
		useDifferentManaged = undefined,
		choosePreferred = undefined,
		verify = false,
		emailOTPState = $bindable(false),
		recommendedProvider = false,
		verifyOTPAjax = $bindable(false),
		success = () => {},
		error = () => {},
		otpState = () => {}
	} = $props();

	let verifiedEmails = $state([]);

	$effect(() => {
		if (validateOtp(otp)) {
			verifyEmailCode(otp);
		}
	});

	onMount(() => {
		if ((global.data?.isPersonalLoggedIn || global.data?.isManagedLoggedIn) && !login && !verify) {
			if (window.isWalletAuthorizeApp && Array.isArray(global.data.release?.emails)) {
				let _verifiedEmails = [];
				//dont block verifying unverified emails
				if (Array.isArray(global.data.release?.unverified_emails)) {
					_verifiedEmails = global.data.release.emails.filter(
						(email) => !global.data.release.unverified_emails.includes(email)
					);
				} else {
					_verifiedEmails = global.data.release.emails;
				}
				verifiedEmails = _verifiedEmails;
			} else if (
				Array.isArray(global.data.profile?.accounts) &&
				Array.isArray(global.data.profile?.unverified_emails)
			) {
				const _verifiedEmails = global.data.profile.accounts
					.filter(
						(i) =>
							i.slug === 'email' && !global.data.profile.unverified_emails.includes(i.user_name)
					)
					.map((i) => i.user_name);
				verifiedEmails = _verifiedEmails;
			}
		}
	});

	async function verifyEmail(email, resend) {
		let json;
		if (login) json = await postLoginEmail(email, resend);
		else if (verify) json = await postVerifyEmail(email, resend);
		else json = await postLinkEmail(email, resend);
		if (!json.error) {
			verifyEmailPlausible();
			emailOTPState = true;
			otpState();
		}
		ajaxRequestSend = ajaxRequestResend = false;
	}

	// TBD move to JS file
	async function verifyEmailPlausible() {
		//New User Release Funnel
		if (window.isWalletAuthorizeApp) {
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
			//session funnel state is valid and not already sent + is authorize app
			if (
				login &&
				indexOfCurrentFunnelStep !== -1 &&
				indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
				window.isWalletAuthorizeApp
			) {
				const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'client_id'
				);
				const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'redirect_uri'
				);
				let redirect;
				try {
					redirect = new URL(redirect_uri)?.hostname;
				} catch (err) {
					console.error(err);
				}
				logPlausibleEvent({
					n: 'AZ Login Start',
					p: {
						client_id,
						provider: 'email',
						recommended_provider: recommendedProvider,
						redirect
					},
					u: '/login'
				});
				sessionStorage.setItem('az_release_funnel', 'az_login_start');
			}
		}

		//Wizard Funnel
		const isInWizard = !global.data?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		//is trying to link + is in wizard + is wallet app
		if (!login && !verify && isInWizard && window.isWalletApp) {
			const preferred = global.data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				if (sessionStorage.welcome_app_info) {
					try {
						welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
					} catch (err) {
						console.error(err);
					}
				}
			}
			const recovery_1 = global.data?.recovery?.[0]?.slug;
			if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
				await logPlausibleEvent({
					n: 'Wiz Recovery 1 Start',
					p: { preferred, welcome_email_app, recovery_1: 'email' },
					u: '/wizard/recoveryprovider'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start');
			} else if (recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery_1_success') {
				await logPlausibleEvent({
					n: 'Wiz Recovery 2 Start',
					p: { preferred, welcome_email_app, recovery_1, recovery_2: 'email' },
					u: '/wizard/recoveryprovider'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start');
			}
		}
	}

	async function verifyEmailCode(code) {
		verifyOTPAjax = true;
		let json;
		if (login)
			json = await postLoginEmailCode({
				code,
				accountToUse,
				accountSelected,
				useDifferentManaged,
				choosePreferred
			});
		else if (verify) json = await postVerifyEmailCode(code);
		else json = await postLinkEmailCode(code);

		if (json.error) {
			otp = '';
			verifyOTPAjax = false;
			error(json.error);
		} else success({ email });
	}

	let timer;
	let checkEmailProviderAjax = $state(false);
	async function promptProviderLogin(e) {
		showProviderPrompt = false;
		checkEmailProviderAjax = false;
		const domain = e.target.value.split('@')[1];
		if (timer) clearTimeout(timer);
		if (!disabled && domain && isValidEmail(email)) {
			if (login) {
				for (const key in emailDomains) {
					if (emailDomains[key].includes(domain)) {
						showProviderPrompt = key;

						//Start of Email Upgrade Funnel
						//email upgrade funnel state is not already sent
						if (!sessionStorage.email_upgrade_funnel) {
							logPlausibleEvent({
								n: 'Email Upgrade Prompt',
								p: { email_domain: domain },
								u: '/login'
							});
							sessionStorage.setItem('email_upgrade_funnel', 'email_upgrade_prompt');
						}
						return;
					}
				}

				timer = setTimeout(async () => {
					checkEmailProviderAjax = true;
					const { slug } = await getEmailProviderDiscovery(domain);
					if (slug) {
						if (email !== e.target.value) return;

						showProviderPrompt = slug;

						//Start of Email Upgrade Funnel
						//email upgrade funnel state is not already sent
						if (!sessionStorage.email_upgrade_funnel) {
							logPlausibleEvent({
								n: 'Email Upgrade Prompt',
								p: { email_domain: slug },
								u: '/login'
							});
							sessionStorage.setItem('email_upgrade_funnel', 'email_upgrade_prompt');
						}
					}
					checkEmailProviderAjax = false;
				}, 650);
			} else {
				if (verifiedEmails.includes(email)) return;

				timer = setTimeout(async () => {
					checkEmailProviderAjax = true;
					const { slug } = await getEmailProviderDiscovery(domain);
					if (slug) {
						if (email !== e.target.value) return;
						const provider = logins.find((i) => i.slug == slug);
						if (provider) {
							if (provider.claims?.verified_email) {
								showProviderPrompt = slug;
							}
						}
					}
					checkEmailProviderAjax = false;
				}, 650);
			}
		}
	}

	async function continueWithProvider(slug, body) {
		global.spinner = true;

		if (login) {
			const { redirect } = await postLoginProvider({
				slug,
				body
			});

			//Email Upgrade Funnel
			if (sessionStorage.email_upgrade_funnel === 'email_upgrade_prompt') {
				const email_domain = email?.split('@')?.[1];
				await logPlausibleEvent({
					n: 'Email Upgrade Start',
					p: { email_domain, slug },
					u: '/login'
				});
				sessionStorage.setItem('email_upgrade_funnel', 'email_upgrade_start');
			}
			window.location.href = redirect;
		} else {
			const { redirect } = await postLinkProvider({
				slug,
				body
			});
			window.location.href = redirect;
		}
		global.spinner = false;
	}
</script>

{#if !emailOTPState}
	<div id="email-wrapper" class="w-full text-center">
		{#if !disabled}
			<input
				type="email"
				name="email"
				id="email"
				autocomplete="email"
				autocapitalize="off"
				bind:value={email}
				oninput={promptProviderLogin}
				placeholder={$_('enter your email')}
				class="h-12 w-full bg-transparent px-[16px] sm:px-[18px]"
			/>
		{/if}
		{#if checkEmailProviderAjax}
			<div
				class="mt-2 flex items-center justify-start"
				transition:slide={{ duration: SLIDE_ANIM_MS }}
			>
				<SpinnerIcon css="h-5 w-5 text-charcoal dark:text-white" />
				<span class="ml-2 block text-left opacity-80">{$_('Checking')}</span>
			</div>
		{/if}
		{#if !login && disabled}
			<span class="flex h-6 items-center justify-center">{email}</span>
		{/if}

		{#if (login && showProviderPrompt) || (!login && !verifiedEmails.includes(email) && showProviderPrompt)}
			<div data-test="provider-prompt" class="mt-5" transition:slide={{ duration: SLIDE_ANIM_MS }}>
				<h3 class="mx-auto mb-3 w-3/4 font-semibold">
					{login
						? $_('Looks like you are trying to use your {provider} account to log in', {
								values: { provider: getDisplay(showProviderPrompt) }
							})
						: $_('Looks like you are trying to verify a {provider} account', {
								values: { provider: getDisplay(showProviderPrompt) }
							})}
				</h3>
				{#if login}
					<p class="mx-auto -mt-1 mb-3 w-3/4 text-sm italic">
						{$_('Logging in with a social provider is generally more secure than email')}
					</p>
				{/if}
				<LoginProvider
					onclick={() =>
						continueWithProvider(showProviderPrompt, {
							login_hint: email || undefined, // dont send empty login_hint
							accountToUse,
							accountSelected,
							useDifferentManaged
						})}
					prefix="Continue with"
					provider={{ slug: showProviderPrompt, display: getDisplay(showProviderPrompt) }}
				/>
			</div>
		{/if}

		{#if verifiedEmails.includes(email)}
			<span
				data-test="email-exists-error"
				class="mt-2 block text-center text-sm text-red-500"
				transition:slide={{ duration: SLIDE_ANIM_MS }}>{$_('Email has already been verified')}</span
			>
		{/if}

		<button
			data-test="email-send-verification-btn"
			onclick={() => {
				ajaxRequestSend = true;
				verifyEmail(email);
			}}
			disabled={!isValidEmail(email) ||
				ajaxRequestSend ||
				verifiedEmails.includes(email) ||
				checkEmailProviderAjax}
			class="relative transition disabled:opacity-60 {showProviderPrompt
				? 'btn-border'
				: 'btn-background'} inline-flex h-12 w-full items-center justify-center"
			class:mt-3={!disabled || !login}
		>
			{#if ajaxRequestSend}
				<SpinnerIcon
					css="h-5 w-5 {showProviderPrompt ? 'text-charcoal dark:text-[#d4d4d4]' : 'text-white'}"
				/>
			{:else if login}
				{$_('Send verification code to log in')}
			{:else}
				{$_('Send verification code')}
			{/if}
		</button>
	</div>
{:else}
	<div class="text-center" in:fly={{ x: 20, duration: SLIDE_ANIM_MS }}>
		<div class="flex items-center justify-center">
			{#if disabled && login}
				<h1 class="text-center text-lg">{$_('Enter the 6-digit code you received')}</h1>
			{:else}
				<h1 class="text-center text-lg">
					{$_('Enter 6 digit code sent to {contact}', { values: { contact: email } })}
				</h1>
			{/if}
		</div>

		{#if !verifyOTPAjax}
			<SvelteOtp
				bind:value={otp}
				autofocus={true}
				numOfInputs={6}
				wrapperClass="!gap-x-2 h-20 flex items-center justify-center"
				numberOnly={true}
				inputClass="text-lg !w-9 !h-11 !border-none"
			/>

			<button
				data-test="email-resend-verification-btn"
				onclick={preventDefault(() => {
					ajaxRequestResend = true;
					verifyEmail(email, true);
				})}
				disabled={ajaxRequestResend}
				class="relative inline-flex h-5 items-center justify-center text-sm font-medium opacity-80 hover:underline focus:underline"
			>
				{#if ajaxRequestResend}
					<SpinnerIcon css="h-4 w-4 block mx-auto" />
				{:else}
					{$_('Resend verification code')}
				{/if}
			</button>
		{:else}
			<div class="mx-auto flex h-[6.5rem] items-center justify-center">
				<SpinnerIcon css="h-6 w-6 block mx-auto" />
			</div>
		{/if}
	</div>
{/if}
