import { writable, get } from 'svelte/store';
import { SESSION_EXPIRE_MS } from '$src/constants.js';
import { isLoading, locale } from 'svelte-i18n';

const _countdown = () => {
	const { subscribe, set, update } = writable(SESSION_EXPIRE_MS / 1000);
	let interval;
	return {
		subscribe,
		_reset: function () {
			set(SESSION_EXPIRE_MS / 1000);
		},
		start: function () {
			this._reset();
			if (interval) clearInterval(interval);
			interval = setInterval(() => {
				update((n) => n - 1);
			}, 1000);
		},
		clear: function () {
			this._reset();
			clearInterval(interval);
		}
	};
};

const _notification = () => {
	const { subscribe, set } = writable({});
	return {
		subscribe,
		show: (text, type) => {
			// ensure locale file is loaded
			const localeExists = get(locale);
			const localeLoaded = !get(isLoading);
			if (localeExists && localeLoaded) {
				set({ text, type });
			}
		},
		clear: () => set({})
	};
};

export const countdown = _countdown();
export const notification = _notification();
