<script>
	import TitleBar from '$lib/TitleBar.svelte';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import { global } from '$src/state.svelte.js';
	import { notification } from '$src/stores.js';
	import Notification from '$lib/Notification.svelte';
	import { getProfile } from '$utils/api.js';
	import { onMount } from 'svelte';
	import { setAttributes } from '$utils/common.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	// import {
	// 	promptForPasskey
	// } from '$utils/passkey.js';
	import LoginProfile from '$lib/LoginProfile.svelte';

	// let showPasskeyLogin = false;

	onMount(async () => {
		global.spinner = true;

		//remove all outstanding modals
		global.upgradePreferred = null;
		global.mergeAccounts = null;

		//we dont have profile data
		if (!global.data?.version) {
			global.data = await getProfile();
		}

		if (global.data.isPersonalLoggedIn) return replaceRoute('/');
		if (global.data.isManagedLoggedIn && !global.data?.preferred?.length)
			return replaceRoute('/wizard/welcome');

		// uncomment to start prompting for passkey
		// showPasskeyLogin = (await promptForPasskey()) && global.data.hasPasskey;

		logPlausibleEvent({ u: '/login' });
		global.spinner = false;
	});
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if !global.spinner}
	<main class="flex-1 overflow-y-auto pb-16">
		<div class="container mx-auto mt-8 max-w-md px-6 text-center">
			<LoginProfile />
		</div>
	</main>

	<wc-footer use:setAttributes></wc-footer>
{/if}
