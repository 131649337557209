<script>
	import ChevronDownIcon from '$lib/icon/ChevronDownIcon.svelte';

	let {
		expanded = false,
		ariaLabel = '',
		id = undefined,
		dataTest = undefined,
		disabled = false,
		hasBackground = true,
		cssClass = '',
		hideControl = false,
		children,
		onclick = () => {}
	} = $props();

	let hover = $state(false);
</script>

<div
	{id}
	class="relative h-auto w-full rounded-md {cssClass}"
	class:btn-background={!expanded && hasBackground}
	class:box-shadow-hover={hover || (expanded && hasBackground)}
	class:box-shadow={!hasBackground}
	class:opacity-50={disabled}
	class:cursor-not-allowed={disabled}
>
	<button
		data-test={dataTest}
		aria-label={ariaLabel}
		onmouseenter={() => (hover = true)}
		onmouseleave={() => (hover = false)}
		onfocus={() => (hover = true)}
		onblur={() => (hover = false)}
		{onclick}
		{disabled}
		class="absolute z-20 flex h-12 w-full items-center justify-end px-4"
		class:invisible={hideControl}
	>
		<ChevronDownIcon rotate={expanded} {hover} />
	</button>

	{@render children?.()}
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}

		.box-shadow-hover:not(:disabled) {
			box-shadow: 0 0 0 2px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}

		.box-shadow-hover:not(:disabled) {
			box-shadow: 0 0 0 2px #303030;
		}
	}
</style>
