<script>
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import TitleBar from '$lib/TitleBar.svelte';
	import { global } from '$src/state.svelte.js';
	import { notification } from '$src/stores.js';
	import { getProfile, putPreferred } from '$utils/api.js';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import Notification from '$lib/Notification.svelte';
	import { PutAuthorityButton } from '$lib/button/';
	import { FADE_ANIM_DELAY_MS } from '$src/constants.js';
	import { setAttributes, getDisplay, dedupe } from '$utils/common.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { clearLocalAndSessionStorage } from '$utils/storage.js';
	import tippy from 'sveltejs-tippy';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import PhoneIcon from '$lib/icon/PhoneIcon.svelte';
	import InfoIcon from '$lib/icon/InfoIcon.svelte';
	import FullPageModal from '$lib/modal/FullPageModal.svelte';

	onMount(async () => {
		global.spinner = true;

		//remove upgrade modal in upgrade page
		global.upgradePreferred = null;

		//we dont have profile data
		if (!global.data?.version) {
			global.data = await getProfile();
			if (!global.data.isPersonalLoggedIn && !global.data?.isManagedLoggedIn) {
				clearLocalAndSessionStorage();
				return replaceRoute('/login');
			}
			if (global.data.actions?.doneWizardAt) return replaceRoute('/');

			//check if preferred is social
			const preferred = global.data.profile?.accounts?.find((i) => i.preferred);
			const nonSocialProviders = ['email', 'phone'];
			const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug);
			if (isPreferredSocial) {
				nextPage();
			}
		}

		if (!global.data?.preferred?.length) return replaceRoute('/wizard/preferredprovider');

		localStorage.setItem('currentWizardStage', 'upgrade');

		//make preferred after verifying provider
		if (sessionStorage.getItem('authority_preferred')) {
			const res = await putPreferred({ preferred: sessionStorage.getItem('authority_preferred') });
			global.data.profile.accounts = res.accounts;
			sessionStorage.removeItem('authority_preferred');
			nextPage();
		}

		logPlausibleEvent({ u: '/wizard/upgrade' });

		global.spinner = false;
	});

	let showVerifyModal = $state(null);
	let updatePreferredAjax = $state(null);

	function sortByCreatedDate(a, b) {
		return Date.parse(a.createdAt) - Date.parse(b.createdAt);
	}

	function nextPage() {
		//End of Wiz Upgrade Funnel
		sessionStorage.removeItem('wiz_upgrade_funnel');

		const recoveries = global.data.profile?.accounts?.filter(
			(i) => !i.preferred && i.recovery && !i.communal
		);
		if (recoveries.length < 2) {
			return navigateTo('/wizard/incomplete');
		} else {
			return navigateTo('/wizard/status');
		}
	}

	async function updatePreferred(account) {
		updatePreferredAjax = account.id;
		const isAccountSocial = !['ethereum', 'email', 'phone'].includes(account.slug);

		const res = await putPreferred(
			{ preferred: account.id },
			{
				redirectPathParam: isAccountSocial, // only send redirect_path param if social provider
				server: account.mastodonServer || null // only send server param if mastodon
			}
		);

		//End of Wiz Upgrade Funnel
		const isInWizard = !global.data?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		const contact_slug = global.data.upgrade?.preferred?.slug;
		const email_domain = global.data.upgrade?.preferred?.user_name?.split('@')?.[1];
		const upgrade_slug = global.data.upgrade?.upgrade?.slug;
		// wizard upgrade funnel state is not already sent + is in wizard + is wallet app
		if (sessionStorage.wiz_upgrade_funnel && isInWizard && window.isWalletApp) {
			logPlausibleEvent({
				n: 'Wiz Upgrade Success',
				p: { contact_slug, email_domain, upgrade_slug },
				u: '/wizard/upgrade'
			});
			sessionStorage.removeItem('wiz_upgrade_funnel');
		}

		notification.show(
			$_('Changing preferred to {provider}', {
				values: {
					provider: getDisplay(account.slug)
				}
			}),
			'success'
		);
		if (res.verify) {
			showVerifyModal = { id: account.id, ...res.verify };
			if (res.verify.slug === 'mastodon') {
				//Do not show Mastodon dropdown UI - show continue with Mastodon account button
				showVerifyModal.mastodonServer = global.data.profile?.accounts?.find(
					(i) => i.id === account.id
				).mastodonServer;
				showVerifyModal.user_name = global.data.profile?.accounts?.find(
					(i) => i.id === account.id
				).user_name;
			}
		} else {
			global.data.profile.accounts = res.accounts;
			setTimeout(() => {
				notification.clear();
				nextPage();
			}, 1000);
		}
	}

	const displayString = (account) => {
		let string = '';
		if (account.slug === 'ethereum') {
			string += account.wallet?.name;
		} else if (account.slug === 'email' || account.slug === 'phone') {
			// Do nothing
		} else {
			string += getDisplay(account.slug);
		}
		if (account.slug === 'email') {
			string += account.user_name;
		} else if (account.slug === 'phone') {
			string += window.intlTelInputUtils.formatNumber(
				account.user_name,
				null,
				window.intlTelInputUtils.numberFormat.INTERNATIONAL
			);
		} else if (account.user_name) {
			if (account.mastodonServer) {
				string += ` (@${account.user_name}@${account.mastodonServer})`;
			} else {
				string += ` (${account.user_name})`;
			}
		}
		return string;
	};

	function recoveryDisplayString() {
		const recoveries = global.data.profile?.accounts
			?.filter(
				(i) =>
					!i.preferred &&
					i.recovery &&
					!i.communal &&
					!['email', 'phone', 'ethereum'].includes(i.slug)
			)
			.map((i) => i.slug);
		let deduped = [...new Set(recoveries)];
		deduped = deduped.map((i) => getDisplay(i));
		deduped = deduped.toString().replace(/,/g, ', ');
		return deduped;
	}
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if showVerifyModal}
	<FullPageModal dataTest="verify-preferred-modal" close={() => (showVerifyModal = null)}>
		<h3 class="mb-4 text-center font-medium">{$_('Verify your account')}</h3>
		<LoginProvider
			provider={showVerifyModal}
			onclick={async () => {
				sessionStorage.setItem('authority_preferred', showVerifyModal.id);
				await logPlausibleEvent({ u: `/start/verify/${showVerifyModal.slug}`, n: 'action' });
				window.location.href = showVerifyModal.redirect_uri;
			}}
			prefix={$_('Verify')}
		/>
		<button
			class="mr-auto mt-4 block text-sm hover:underline focus:underline"
			onclick={() => (showVerifyModal = null)}>{$_('Cancel')}</button
		>
	</FullPageModal>
{/if}

{#if global.data?.isPersonalLoggedIn && !global.spinner}
	<main class="flex-1 overflow-y-auto pb-16" in:fade={{ delay: 0 * FADE_ANIM_DELAY_MS }}>
		<div class="mb-6 mt-8">
			<div
				class="mx-auto flex max-w-2xl flex-col items-center justify-between space-x-2 px-3 text-sm md:space-x-0"
				in:fade={{ delay: 0 * FADE_ANIM_DELAY_MS }}
			>
				<h1 class="flex-1 border-none text-center text-base font-medium md:text-xl">
					{$_('Change Preferred Login Provider?')}
				</h1>

				<h2
					class="mx-auto mt-3 max-w-md border-none text-center text-base font-medium italic md:text-lg"
				>
					{#if global.data?.profile?.accounts.find((i) => i.preferred).slug === 'email'}
						{$_('Logging in with {provider} is generally more secure than email', {
							values: {
								provider: recoveryDisplayString()
							}
						})}
					{:else if global.data?.profile?.accounts.find((i) => i.preferred).slug === 'phone'}
						{$_('Logging in with {provider} is generally more secure than phone', {
							values: {
								provider: recoveryDisplayString()
							}
						})}
					{/if}
				</h2>
			</div>
			<div class="mx-auto px-3 text-center md:max-w-2xl md:px-0">
				<ul class="mx-auto my-6 max-w-md space-y-3">
					{#each dedupe([...global.data.profile.accounts], 'id')
						.filter((i) => i.recovery && !i.communal && i.verifiedAt && i.slug !== 'ethereum')
						.sort(sortByCreatedDate)
						.sort((a, b) => b.preferred - a.preferred) as account, index (account.id)}
						<li
							data-test="recovery-provider-item-{index}"
							class="w-full rounded-md text-left
								{account.preferred
								? 'bg-charcoal mb-6 rounded-md bg-opacity-80 p-3 text-white dark:text-[#D4D4D4]'
								: 'py-1'}"
							in:fade={{ delay: (index + 1) * FADE_ANIM_DELAY_MS }}
						>
							{#if account.preferred}
								<label for="preferred-provider" class="text-lg font-bold"
									>{$_('Current Preferred Provider')}</label
								>
								<div class="my-1 ml-4 flex w-full items-center">
									<div class="w-4.5 inline-flex h-9 max-h-[18px] items-center">
										{#if account.slug === 'ethereum'}
											<img src={account.wallet.icon} alt={account.wallet.name} class="h-4" />
										{:else if account.slug === 'email'}
											<MailIcon />
										{:else if account.slug === 'phone'}
											<PhoneIcon />
										{:else if account.mastodonServer}
											<!-- TBD Broken -- Defaulting to default mastodon logo -->
											<!-- <img
												src="https://{account.mastodonServer}/favicon.ico"
												alt="Mastodon"
												class="h-6"
											/> -->
											<img
												src="https://cdn.hello.coop/images/mastodon.svg"
												alt="Mastodon"
												class="h-6"
											/>
										{:else}
											<img
												src="https://cdn.hello.coop/images/{account.slug}.svg"
												alt="{account.slug} logo"
												class="h-6 {['apple', 'twitter'].includes(account.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
													? 'dark:invert-20 invert'
													: ''}"
											/>
										{/if}
									</div>
									<div data-test="preferred-provider-label" id="preferred-provider" class="ml-1.5">
										{#if account.slug === 'ethereum'}
											<span use:tippy={{ content: account.login_hint, placement: 'top' }}>
												{displayString(account)}
											</span>
										{:else}
											<span>
												{displayString(account)}
											</span>
										{/if}
									</div>
								</div>
								{#if account.slug === 'email'}
									<span class="my-2 text-sm font-light italic"
										>{$_('Your preferred login is email with verification code')}</span
									>
								{:else if account.slug === 'phone'}
									<span class="my-2 text-sm font-light italic"
										>{$_('Your preferred login is phone with verification code')}</span
									>
								{/if}
							{:else}
								<div class="flex items-center">
									<div class="mt-0.5 w-full flex-1">
										<div class="flex flex-1 items-center justify-between">
											<div class="inline-flex w-full items-center truncate text-left font-medium">
												<div class="w-9 flex-shrink-0">
													{#if account.slug === 'ethereum'}
														<img src={account.wallet.icon} alt={account.wallet.name} class="h-4" />
													{:else if account.slug === 'email'}
														<MailIcon />
													{:else if account.slug === 'phone'}
														<PhoneIcon />
													{:else if account.mastodonServer}
														<!-- TBD Broken -- Defaulting to default mastodon logo -->
														<!-- <img
															src="https://{account.mastodonServer}/favicon.ico"
															alt="Mastodon"
															class="h-6"
														/> -->
														<img
															src="https://cdn.hello.coop/images/mastodon.svg"
															alt="Mastodon"
															class="h-6"
														/>
													{:else}
														<img
															src="https://cdn.hello.coop/images/{account.slug}.svg"
															alt="{account.slug} logo"
															class="h-6 {['apple', 'twitter'].includes(account.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
																? 'dark:invert-20 invert'
																: ''}"
														/>
													{/if}
												</div>
												<span class="block truncate" class:font-bold={account.preferred}>
													{#if account.slug === 'ethereum'}
														<span use:tippy={{ content: account.login_hint, placement: 'top' }}>
															{displayString(account)}
														</span>
													{:else}
														<span>
															{displayString(account)}
														</span>
													{/if}
												</span>
											</div>

											<div class="ml-4 flex-shrink-0">
												{#if ['email', 'phone', 'ethereum'].includes(account.slug)}
													<span class="text-xs font-medium uppercase">{$_('Recovery')}</span>
												{:else}
													<PutAuthorityButton
														dataTest="make-preferred-{index}-btn"
														onclick={() => updatePreferred(account)}
														value="Make Preferred"
														isLoading={updatePreferredAjax === account.id}
													/>
												{/if}
											</div>
										</div>
									</div>
								</div>
							{/if}
						</li>
					{/each}
				</ul>

				<div
					class="mx-auto max-w-md"
					in:fade={{ delay: (global.data.profile?.accounts.length + 2) * 50 }}
				>
					<button
						data-test="not-now-btn"
						onclick={nextPage}
						class="mr-auto block hover:underline focus:underline disabled:cursor-wait disabled:no-underline disabled:opacity-50"
					>
						{$_('Not now')}
					</button>

					<div class="mx-auto mt-4 flex w-full max-w-md items-center text-sm opacity-60">
						<InfoIcon />
						<span class="ml-2">{$_('You can change your providers at wallet.hello.coop')}</span>
					</div>
				</div>
			</div>
		</div>
	</main>

	<wc-footer use:setAttributes></wc-footer>
{/if}
