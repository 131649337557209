// eslint complaints $state in *.svelte.js
// eslint-disable-next-line
export const global = $state({
	data: {},
	spinner: false,
	upgradePreferred: null,
	mergeAccounts: null,
	isRemoteAuthClient: false,
	helloPop: 'unknown',
	unrecoverableError: false
});
