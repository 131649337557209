<script>
	import { _, locale } from 'svelte-i18n';
	import { RECOMMENDED_MANAGED_LOGO_SIZE } from '$src/constants.js';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';

	let {
		loading = false,
		disabled = false,
		logo = null,
		fileName = '',
		size = {},
		mimeType = '',
		onclick = () => {}
	} = $props();
</script>

<button
	{disabled}
	class="h-18 btn-background group relative flex w-full items-center justify-start overflow-hidden px-4 disabled:cursor-not-allowed disabled:opacity-50"
	class:cursor-not-allowed={disabled}
	{onclick}
>
	{#if loading}
		<SpinnerIcon css="h-6 w-6 block mx-auto" />
	{:else}
		{@const isNotRecommendedSize =
			!mimeType?.includes('svg') &&
			Math.max(size.width, size.height) < RECOMMENDED_MANAGED_LOGO_SIZE}
		<span class="managed-rounded-square-lg">
			<img src={logo} alt="logo" class="mt-0 max-h-[36px] w-9 object-contain" />
		</span>

		<div
			class="{$locale && $locale.startsWith('ar')
				? 'mr-4 text-right'
				: 'ml-4 text-left'} -mr-3 w-full truncate"
		>
			<span class="block">
				{$_('Use this logo')}
			</span>

			<div class="flex w-full items-center justify-between gap-x-2">
				<span class="block truncate">
					{fileName}
				</span>

				<span
					class="block flex-shrink-0 font-light tracking-tight"
					class:text-red-500={isNotRecommendedSize}
				>
					{#if mimeType?.includes('svg')}
						SVG
					{:else if Number.isInteger(size.width) && Number.isInteger(size.height)}
						{size.width} &times; {size.height}
					{/if}
				</span>
			</div>
		</div>

		<!-- Right arrow icon -->
		<svg
			xmlns="http://www.w3.org/2000/svg"
			class="flex-shrink-0 {$locale && $locale.startsWith('ar')
				? 'mr-auto rotate-90'
				: 'ml-auto -rotate-90'} h-4.5 group-focus:stroke-3 group-hover:stroke-3 mb-5.5 transform text-white opacity-80 dark:text-[#d4d4d4]"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			stroke-width="2"
		>
			<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
		</svg>
	{/if}
</button>
